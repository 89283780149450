export enum Color {
  Black = 'black',
  DarkEvergreen = 'dark-evergreen',
  Evergreen = 'evergreen',
  LightEvergreen = 'light-evergreen',
  DarkSage = 'dark-sage',
  Sage = 'sage',
  LightSage = 'light-sage',
  HoverSage = 'hover-sage',
  DarkSunsetSand = 'dark-sunsetsand',
  SunsetSand = 'sunsetsand',
  LightSunsetSand = 'light-sunsetsand',
  Paper = 'paper'
}
