export enum ClientOption {
  ThreeSidedCube,
  Cherryz,
  Zilch,
  PerchPeek,
  EVEnergy,
  Perception,
  Wildhero,
  AndDigital,
  CleanMe
}

export class Client {
  public name: string;

  public logo: string;

  public link: string;

  constructor(name: string, logo: string, link: string) {
    this.name = name;
    this.logo = logo;
    this.link = link;
  }

  static getClientLogo(client: ClientOption): Client {
    switch (client) {
      case ClientOption.ThreeSidedCube:
        return new Client('3 Sided Cube', 'three-sided-cube', 'https://3sidedcube.com');
      case ClientOption.Cherryz:
        return new Client('Cherryz', 'cherryz', 'https://www.cherryz.com');
      case ClientOption.Zilch:
        return new Client('Zilch', 'zilch', 'https://www.zilch.com/');
      case ClientOption.PerchPeek:
        return new Client('PerchPeek', 'perchpeek', 'https://www.perchpeek.com/');
      case ClientOption.EVEnergy:
        return new Client('ev.energy', 'evenergy', 'https://www.ev.energy/');
      case ClientOption.Perception:
        return new Client('Perception', 'perception', 'https://perception-viewer.app.link/iSSTEkeimLb');
      case ClientOption.AndDigital:
        return new Client('AND Digital', 'anddigital', 'https://and.digital');
      case ClientOption.Wildhero:
        return new Client('Wildhero', 'wildhero', 'https://mail.wildhero.com/');
      case ClientOption.CleanMe:
        return new Client('CleanMe', 'cleanme', 'https://cleanme.co.uk');
      default:
        throw new Error(`getClientLogo - missing logo for ${client}`);
    }
  }
}
