import './Corner.scss';

import React from 'react';

import { CornerViewModel } from '../../../models/corner';

type Props = {
  model: CornerViewModel;
};

export const Corner: React.FC<Props> = ({ model }) => (
  <div className={`corner corner-${model.yPosition}-${model.xPosition} b-${model.color}`} />
);
