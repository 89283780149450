import React from 'react';

import { ClientContainer } from '../components/ClientContainer/ClientContainer';
import { ContactCardContainer } from '../components/ContactContainer/ContactCardContainer';
import { Header } from '../components/Header/Header';
import HelmetMetadata from '../components/HelmetMetadata/HelmetMetadata';
import ProjectCardContainer from '../components/ProjectContainer/ProjectContainer';

import { ClientContainerViewModel } from '../models/client-container';
import { Color } from '../models/color';
import { ContactCardContainerViewModel } from '../models/contact-card-container';
import { CurrentPage } from '../models/current-page';
import { HeaderViewModel } from '../models/header';
import { ProjectCardContainerViewModel } from '../models/ProjectCardContainerViewModel';

const currentPage = CurrentPage.Projects;

export const Projects = () => (
  <div>
    <HelmetMetadata title="Projects | Peslo" />
    <Header model={HeaderViewModel.getProjectsHeader(currentPage)} />
    <ClientContainer model={ClientContainerViewModel.getBasicClientContainerModel(Color.SunsetSand)} />
    <ProjectCardContainer model={new ProjectCardContainerViewModel(Color.HoverSage, currentPage)} />
    <ContactCardContainer model={ContactCardContainerViewModel.getMissionContainer(currentPage)} />
  </div>
);
