import React from 'react';

import { ClientContainer } from '../components/ClientContainer/ClientContainer';
import { ContactCardContainer } from '../components/ContactContainer/ContactCardContainer';
import { Header } from '../components/Header/Header';
import HelmetMetadata from '../components/HelmetMetadata/HelmetMetadata';
import { Quote } from '../components/Quote/Quote';
import { ServiceIconContainer } from '../components/ServiceIconContainer/ServiceIconContainer';
import { SideImageContainer } from '../components/SideImageContainer/SideImageContainer';
import { TextBannerContainer } from '../components/TextBannerContainer/TextBannerContainer';

import { ButtonStyleType, ButtonType, ButtonViewModel } from '../models/button';
import { ClientContainerViewModel } from '../models/client-container';
import { Color } from '../models/color';
import { ContactCardContainerViewModel } from '../models/contact-card-container';
import { CurrentPage } from '../models/current-page';
import { HeaderViewModel } from '../models/header';
import { IconContainerViewModel } from '../models/icon-container';
import { QuoteBy, QuoteViewModel } from '../models/quote';
import { SideImageContainerViewModel } from '../models/side-image-container';
import { TextOption, TextViewModel } from '../models/text';
import { TextBannerViewModel } from '../models/text-banner';

const getTextBanner = (): TextBannerViewModel => {
  const header = new TextViewModel('The World We Share', Color.Paper, TextOption.HeaderOne, Color.SunsetSand);

  const button = new ButtonViewModel('Hire us', '/contact', ButtonStyleType.Secondary, ButtonType.Link);

  return new TextBannerViewModel(Color.Evergreen, header, undefined, button);
};

const currentPage = CurrentPage.Peslo;

export const Peslo = () => (
  <div>
    <HelmetMetadata title="About | Peslo" />
    <Header model={HeaderViewModel.getPesloHeader(currentPage)} />
    <Quote model={QuoteViewModel.getQuoteFor(QuoteBy.Ryan, Color.Paper)} />
    <TextBannerContainer model={getTextBanner()}>
      <div className="my-4">
        <p className="f-paper text-center">
          We're more and more aware of the adverse effects humans have on the world, not just on the world but on one
          another. We see this in our day to day lives: the climate crisis, conflict, economic uncertainty.
        </p>
        <p className="f-paper text-center">
          Through incremental and continuous changes, we can help to reverse those effects. At Peslo, we partner with
          innovative start-ups, charities, and NGOs to deliver projects that protect the environment, help disadvantaged
          communities, and help people prepare for the future. We only work on projects that deliver positive impact -
          life is too short to do anything else!
        </p>
      </div>
    </TextBannerContainer>
    <SideImageContainer model={SideImageContainerViewModel.getPesloMission()} />
    <ServiceIconContainer model={IconContainerViewModel.getPesloModel()} />
    <ClientContainer model={ClientContainerViewModel.getClientContainerModelWithButton(Color.HoverSage, Color.Sage)} />
    <ContactCardContainer model={ContactCardContainerViewModel.getMissionContainer(currentPage)} />
  </div>
);
