import './ProjectIcon.scss';

import React from 'react';

import { Color } from '../../../../models/color';

type Props = {
  color: Color;
  image?: string;
};

export const ProjectIcon: React.FC<Props> = ({ color, image }) => (
  <div className="icon-container w-100 d-flex justify-content-center align-items-center">
    <div className={`icon bg-${color} p-3 d-flex justify-content-center align-items-center`}>
      <img src={image ? `images/projects/icon/${image}.svg` : 'images/star.svg'} alt="icon" />
    </div>
  </div>
);
