import './CompanyLogo.scss';

import React from 'react';

import Lottie from 'lottie-react';

import { LogoType } from '../../models/logo';

import animatedLogo from './logo_animated.json';

type Props = {
  type: LogoType;
};

function getLogoFile(type: LogoType): string {
  switch (type) {
    case LogoType.FullWhite:
      return '/images/logo-white.svg';
    case LogoType.FullSand:
      return '/images/logo-sand.svg';
    case LogoType.SmallSand:
      return '/images/small-logo-sand.svg';
    default:
      throw new Error('An unsupported type has been provided - likely it instead requires an animation file');
  }
}

export const CompanyLogo: React.FC<Props> = ({ type }) => (
  <a href="/" title="Peslo Home Page">
    {type === LogoType.AnimatedSand && <Lottie className="company-logo" animationData={animatedLogo} loop={false} />}
    {type !== LogoType.AnimatedSand && (
      <img className="company-logo img-fluid" src={getLogoFile(type)} alt="Company Logo" />
    )}
  </a>
);
