import './ServiceCardContainer.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { ServiceCardContainerViewModel } from '../../../models/service-card';

import { getUnderscoreClasses } from '../../../constants/underscore';

import { ServiceCard } from './ServiceCard/ServiceCard';

type Props = {
  model: ServiceCardContainerViewModel;
};

export const ServiceCardContainer: React.FC<Props> = ({ model }) => (
  <div className="d-flex flex-column justify-content-center mt-5">
    <div className="d-inline-flex my-4 text-banner-title justify-content-center">
      <h2
        className={`h1 m-0 text-center ${model.header.color ? `f-${model.header.color}` : ''} ${getUnderscoreClasses(
          model.header.underscore
        )}`}>
        {model.header.text}
      </h2>
    </div>
    <div className="row d-flex justify-content-center align-items-center">
      <div className="row service-card-container d-flex justify-content-center align-items-start">
        <ServiceCard model={model.cards[0]} />
        <ServiceCard model={model.cards[1]} />
      </div>
    </div>

    <div className="mx-lg-5 mt-5 service-card-row">
      <div className="bg-hover-sage">
        <p className={`f-${Color.Black} text-center py-2`}>
          Unsure of what you need? Don't worry! We'll guide you to the{' '}
          <a className={`f-${Color.Black} p`} href="/contact">
            best solution
          </a>{' '}
          that aligns with your vision
        </p>
      </div>
    </div>
  </div>
);
