import './StepContainer.scss';

import React from 'react';

import { StepContainerViewModel } from '../../models/steps';

import { getUnderscoreClasses } from '../../constants/underscore';

import { Step } from './Step/Step';

type Props = {
  model: StepContainerViewModel;
};

export const StepContainer: React.FC<Props> = ({ model }) => {
  const steps = model.steps.map(step => <Step key={step.step} model={step} />);

  return (
    <div className={`container-xxl py-5 ${model.backgroundColor ? `bg-${model.backgroundColor}` : ''}`}>
      <div className="py-5">
        <div className="w-100 d-flex justify-content-center">
          <div className="d-inline-flex pb-5">
            <h2
              className={`h1 m-0 text-center f-${
                model.titleColor ? model.titleColor : 'sunset-sand'
              } ${getUnderscoreClasses(model.secondaryColor)}`}>
              {model.title}
            </h2>
          </div>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <div className="steps-container">{steps}</div>
        </div>
      </div>
    </div>
  );
};
