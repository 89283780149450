import './SideImageContainer.scss';

import React from 'react';

import { ButtonViewModel } from '../../models/button';
import { Color } from '../../models/color';
import { ImageBadgeViewModel } from '../../models/image-badge';
import { ImageContainerViewModel } from '../../models/image-container';
import { Position } from '../../models/position';
import { SideImageContainerViewModel } from '../../models/side-image-container';

import { getUnderscoreClasses } from '../../constants/underscore';

import { Button } from '../Button/Button';
import { ImageContainer } from '../ImageContainer/ImageContainer';

type Props = {
  model: SideImageContainerViewModel;
};

export const SideImageContainer: React.FC<Props> = ({ model }) => {
  function renderButtonIfFound(button: ButtonViewModel | undefined) {
    return button && <Button model={button} />;
  }

  function getSideImage(image: ImageContainerViewModel, subImages?: ImageBadgeViewModel[]) {
    return (
      <div className="col-12 col-lg-6 px-5 side-container-image py-5">
        <ImageContainer model={image} subImages={subImages} />
      </div>
    );
  }

  function getSideContent() {
    return (
      <div className="col-12 col-lg-6 px-3 py-5 side-container-text">
        <div className="d-inline-flex">
          <h2
            className={`h1 text-lowercase text-center f-evergreen mb-0 ${getUnderscoreClasses(
              model.underscoreColor ?? Color.SunsetSand
            )}`}>
            {model.title}
          </h2>
        </div>
        <p className="f-evergreen body-text">{model.text}</p>
        <div className="mt-auto side-container-button">{renderButtonIfFound(model.button)}</div>
      </div>
    );
  }

  return (
    <div className="container-xxl p-5 side-image-container">
      <div className="row">
        {model.imagePosition === Position.Start && getSideImage(model.image, model.subImages)}
        {getSideContent()}
        {model.imagePosition === Position.End && getSideImage(model.image, model.subImages)}
      </div>
    </div>
  );
};
