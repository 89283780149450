import React from 'react';

import { ContactCardContainer } from '../components/ContactContainer/ContactCardContainer';
import { Header } from '../components/Header/Header';
import HelmetMetadata from '../components/HelmetMetadata/HelmetMetadata';
import { ServiceContainer } from '../components/ServiceContainer/ServiceContainer';
import { ServiceIconContainer } from '../components/ServiceIconContainer/ServiceIconContainer';
import { StepContainer } from '../components/StepsContainer/StepContainer';

import { ContactCardContainerViewModel } from '../models/contact-card-container';
import { CurrentPage } from '../models/current-page';
import { HeaderViewModel } from '../models/header';
import { IconContainerViewModel } from '../models/icon-container';
import { ServiceContainerViewModel } from '../models/service';
import { StepContainerViewModel } from '../models/steps';

const currentPage = CurrentPage.Services;

export const Services = () => (
  <div>
    <HelmetMetadata title="Services | Peslo" />
    <Header model={HeaderViewModel.getServicesHeader(currentPage)} />
    <ServiceContainer model={ServiceContainerViewModel.getServiceContent()}>
      <div className="text-center">
        <p>
          We're at the forefront of app development - creating reliable, life-saving, award-winning apps that have real
          impact and deliver change to the world we share. We focus on one thing, and do it right. Life is too short to
          do anything else!
        </p>
        <p>
          With our years of experience in mobile development and in line with best practices, we work with you and
          deliver your vision and mission into world-class software.
        </p>
      </div>
    </ServiceContainer>
    <StepContainer model={StepContainerViewModel.pesloProcessContainer()} />
    <ServiceIconContainer model={IconContainerViewModel.getServicesModel()} />
    <ContactCardContainer model={ContactCardContainerViewModel.getMissionContainer(currentPage)} />
  </div>
);
