import './Step.scss';

import React from 'react';

import { StepViewModel } from '../../../models/StepViewModel';

import { getUnderscoreClasses } from '../../../constants/underscore';

type Props = {
  model: StepViewModel;
};

export const Step: React.FC<Props> = ({ model }) => (
  <div className="row px-5 py-4 step-container">
    <div className="step-number-container col-md-4">
      <div
        className={`p-5 d-flex justify-content-start step-number-content-container ${
          model.stepBackgroundColor ? `bg-${model.stepBackgroundColor}` : ''
        }`}>
        <span
          className={`h1 text-center ${model.titleColor ? `f-${model.titleColor}` : ''} ${getUnderscoreClasses(
            model.titleColor
          )}`}>
          {model.step}
        </span>
      </div>
    </div>

    <div className="col-12 col-md-8 step-content-container">
      <h3
        className={`py-3 step-title ${model.stepBackgroundColor ? `bg-${model.stepBackgroundColor}` : ''} ${
          model.titleColor ? `f-${model.titleColor}` : ''
        }`}>
        Step {model.step}
      </h3>
      <p className={model.textColor ? `f-${model.textColor}` : ''}>{model.stepContent}</p>
    </div>
  </div>
);
