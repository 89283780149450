import { ImageExtension } from './image';

export class ImageBadgeViewModel {
  url: string;

  extension: ImageExtension;

  retina: boolean;

  alt: string;

  customClass: string;

  href: string;

  constructor(url: string, extension: ImageExtension, retina: boolean, alt: string, customClass: string, href: string) {
    this.url = url;
    this.extension = extension;
    this.retina = retina;
    this.alt = alt;
    this.customClass = customClass;
    this.href = href;
  }

  static getSubImageContainer(
    url: string,
    extension: ImageExtension,
    retina: boolean,
    alt: string,
    customClass: string,
    href: string
  ) {
    return new ImageBadgeViewModel(url, extension, retina, alt, customClass, href);
  }
}
