import './TextBannerContainer.scss';

import React, { ReactElement } from 'react';

import { TextOption, TextViewModel } from '../../models/text';
import { TextBannerViewModel } from '../../models/text-banner';

import { getUnderscoreClasses } from '../../constants/underscore';

import { Button } from '../Button/Button';

type Props = {
  model: TextBannerViewModel;
  children: ReactElement;
};

export const TextBannerContainer: React.FC<Props> = ({ children, model }) => {
  const setHeader = (header: TextViewModel | undefined) => {
    if (!header) {
      return null;
    }

    switch (header.textOption) {
      case TextOption.HeaderOne:
        return (
          <div className="d-inline-flex my-4 text-banner-title">
            <h2
              className={`h1 text-center ${header.color ? `f-${header.color}` : ''} ${getUnderscoreClasses(
                header.underscore
              )}`}>
              {header.text}
            </h2>
          </div>
        );
      case TextOption.HeaderThree:
        return <h3 className={`my-4 text-center ${header.color ? `f-${header.color}` : ''}`}>{header.text}</h3>;
      default:
        return null;
    }
  };

  return (
    <div
      className={`text-banner-main-container container-xxl py-5 px-3 justify-content-center ${
        model.backgroundColor ? `bg-${model.backgroundColor}` : ''
      }`}>
      <div className="row-fluid d-flex flex-column justify-content-center align-items-center py-3">
        <div className="text-banner-container d-flex flex-column justify-content-center align-items-center">
          {setHeader(model.header)}
          {children}
          {setHeader(model.subHeading)}
          {model.button && (
            <div className="my-4">
              <Button model={model.button} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
