import React from 'react';

import { IconType, IconViewModel } from '../../../models/icon';

type Props = {
  type: IconType;
};

export const ServiceIcon: React.FC<Props> = ({ type }) => {
  const model: IconViewModel = IconViewModel.getIconByType(type);

  return (
    <div className="w-100 py-2 py-lg-3 d-flex flex-row justify-content-stretch align-items-stretch">
      <div
        className={`col-3 ${
          model.backgroundColor ? `bg-${model.backgroundColor}` : null
        } icon-img d-flex justify-content-center align-content-center`}>
        <div className="p-4">
          <img className="img-fluid" src={`/images/icons/${model.url}.svg`} alt={model.alt} />
        </div>
      </div>
      <div
        className={`col-9 py-4 d-flex justify-content-left align-items-center icon-text-container ${
          model.textBackgroundColor ? `bg-${model.textBackgroundColor}` : null
        }`}>
        <div>
          <h3 className={`h3 m-0 ${model.textColor ? `f-${model.textColor}` : ''} bold text-uppercase text-left`}>
            {model.text}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ServiceIcon;
