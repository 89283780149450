import { Color } from './color';
import { Position } from './position';

export enum QuoteType {
  StartQuote = 'Start quote',
  EndQuote = 'End quote'
}

export class QuoteIconViewModel {
  backgroundColor: Color | undefined;

  yPosition: Position;

  xPosition: Position;

  type: QuoteType;

  constructor(backgroundColor: Color | undefined, yPosition: Position, xPosition: Position, type: QuoteType) {
    this.backgroundColor = backgroundColor;
    this.yPosition = yPosition;
    this.xPosition = xPosition;
    this.type = type;
  }

  static getStartQuote(backgroundColor: Color | undefined) {
    return new QuoteIconViewModel(backgroundColor, Position.Start, Position.Start, QuoteType.StartQuote);
  }

  static getEndQuote(backgroundColor: Color | undefined) {
    return new QuoteIconViewModel(backgroundColor, Position.End, Position.End, QuoteType.EndQuote);
  }
}
