import './ProjectContainer.scss';

import React from 'react';

import { ButtonStyleType, ButtonType, ButtonViewModel } from '../../models/button';
import { ClientOption } from '../../models/client';
import { Color } from '../../models/color';
import { ProjectCardViewModel } from '../../models/project-card';
import { ProjectCardContainerViewModel } from '../../models/ProjectCardContainerViewModel';
import { QuoteBy, QuoteViewModel } from '../../models/quote';

import { isProjectsPage } from '../../utils/currentPage';

import { getUnderscoreClasses } from '../../constants/underscore';

import { Button } from '../Button/Button';
import { Quote } from '../Quote/Quote';

import { ProjectCard } from './ProjectCard/ProjectCard';

type Props = {
  model: ProjectCardContainerViewModel;
};

export const ProjectContainer: React.FC<Props> = ({ model }) => {
  const projects = [
    ClientOption.Wildhero,
    ClientOption.CleanMe,
    ClientOption.EVEnergy,
    ClientOption.Perception,
    ClientOption.Zilch,
    ClientOption.Cherryz,
    ClientOption.PerchPeek
  ];
  const quotes = [QuoteBy.RyanReProjects];

  function renderProjectButton(): JSX.Element | null {
    if (!isProjectsPage(model.currentPage)) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Button model={new ButtonViewModel('View Projects', '/projects', ButtonStyleType.Primary, ButtonType.Link)} />
        </div>
      );
    }

    return null;
  }

  function renderProjectCard(index: number): JSX.Element | null {
    if (index <= projects.length - 1) {
      return <ProjectCard model={ProjectCardViewModel.getProjectCard(projects[index], undefined)} />;
    }

    return null;
  }

  function renderQuote(index: number): JSX.Element | null {
    if (index <= quotes.length - 1) {
      return <Quote model={QuoteViewModel.getQuoteFor(quotes[index], model.background)} />;
    }

    return null;
  }

  function getProjectElements(): JSX.Element[] {
    let quote = 0;
    const html: JSX.Element[] = [];

    for (let i = 0; i < (projects.length - 1) * 3; i += 3) {
      html.push(
        <div key={`project-card-${i}`}>
          <div className="row equal d-flex flex-wrap justify-content-center">
            {renderProjectCard(i)}
            {renderProjectCard(i + 1)}
            {renderProjectCard(i + 2)}
          </div>

          {renderQuote(quote)}
        </div>
      );

      quote += 1;
    }

    return html;
  }

  const projectHtml: JSX.Element[] = getProjectElements();

  return (
    <div className={`container-xxl py-5 ${model.background ? `bg-${model.background}` : ''}`}>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="d-inline-flex justify-content-center mb-3 mb-lg-5">
          <h3 className={`h1 f-evergreen text-center ${getUnderscoreClasses(Color.Sage)}`}>{model.title}</h3>
        </div>
      </div>
      {projectHtml.map(project => project)}

      {renderProjectButton()}
    </div>
  );
};

export default ProjectContainer;
