import React, { useCallback, useEffect, useRef, useState } from 'react';

import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import animatedLogo from './hamburger-animated.json';

interface Props {
  onPress: () => void;
}

const HamburgerIcon = ({ onPress }: Props) => {
  const [nextWillReverse, setNextWillReverse] = useState(true);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    // Stops Lottie from playing when the component is first
    // mounted, as apparently autoPlay sometimes won't!
    lottieRef?.current?.stop();
  }, [lottieRef]);

  const onPressInternal = useCallback(() => {
    // Play the animation, and then set the direction for the next play.
    lottieRef?.current?.play();
    lottieRef?.current?.setDirection(nextWillReverse ? 1 : -1);
    setNextWillReverse(!nextWillReverse);
    onPress();
  }, [lottieRef, nextWillReverse, onPress]);

  return (
    <div className="d-flex justify-content-end">
      <button
        className="navbar-toggler"
        type="button"
        aria-controls="navbarToggleMobile"
        aria-label="Toggle navigation"
        onClick={onPressInternal}>
        <Lottie
          autoPlay={false}
          loop={false}
          className="navbar-toggler-icon"
          lottieRef={lottieRef}
          animationData={animatedLogo}
        />
      </button>
    </div>
  );
};

export default HamburgerIcon;
