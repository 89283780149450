import './ProjectRating.scss';

import React, { useMemo } from 'react';

import { Color } from '../../../../models/color';

interface Props {
  number: number;
  rating: number;
}

const getReviewStar = (assetName: string, index: number, altText: string) => (
  <img src={`images/${assetName}.svg`} alt={altText} key={`${assetName}-${index}`} />
);

export const ProjectRating = ({ number, rating }: Props) => {
  const ratingString = useMemo(
    () => rating.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }),
    [rating]
  );

  return (
    <div key={`project-rating-${number}`} className="d-flex justify-content-center align-items-center pb-2">
      {Array.from({ length: Math.floor(rating) }, (_, index) => getReviewStar('star-sunsetsand', index, 'star icon'))}
      {Array.from({ length: Math.ceil(5 - rating) }, (_, index) =>
        getReviewStar('star-partial-sunsetsand', index, 'partial star icon')
      )}
      <h3 className={`f-${Color.DarkSunsetSand} ps-1 mb-0`}>{ratingString}</h3>
    </div>
  );
};
