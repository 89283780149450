import { ButtonStyleType, ButtonType, ButtonViewModel } from './button';
import { Color } from './color';

export class ClientContainerViewModel {
  public background: Color | undefined;

  public underScoreColor: Color;

  public button: ButtonViewModel | undefined;

  constructor(background: Color | undefined, underScoreColor: Color, button: ButtonViewModel | undefined) {
    this.background = background;
    this.underScoreColor = underScoreColor;
    this.button = button;
  }

  static getBasicClientContainerModel(underScoreColor: Color): ClientContainerViewModel {
    return new ClientContainerViewModel(undefined, underScoreColor, undefined);
  }

  static getClientContainerModelWithButton(backgroundColor: Color, underScoreColor: Color): ClientContainerViewModel {
    return new ClientContainerViewModel(
      backgroundColor,
      underScoreColor,
      new ButtonViewModel('View Projects', '/projects', ButtonStyleType.Primary, ButtonType.Link)
    );
  }
}
