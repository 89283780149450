import { isProjectsPage } from '../utils/currentPage';

import { Color } from './color';
import { CurrentPage } from './current-page';

export class ProjectCardContainerViewModel {
  background: Color | undefined;

  title: string;

  currentPage: CurrentPage;

  constructor(background: Color, currentPage: CurrentPage) {
    this.background = background;
    this.currentPage = currentPage;

    if (isProjectsPage(currentPage)) {
      this.title = 'Projects';
    } else this.title = 'Why not check out our other projects';
  }
}
