import './Navigation.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { ClientOption } from '../../models/client';
import { Color } from '../../models/color';
import { CurrentPage } from '../../models/current-page';
import { LogoType } from '../../models/logo';
import { NavigationViewModel } from '../../models/navigation';
import { NavigationItemViewModel } from '../../models/navigation-item';
import { ProjectCardViewModel } from '../../models/project-card';
import { SocialType } from '../../models/social-icon';

import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { ProjectCard } from '../ProjectContainer/ProjectCard/ProjectCard';
import { SocialIcon } from '../SocialIcon/SocialIcon';

import HamburgerIcon from './HamburgerIcon/HamburgerIcon';
import { NavigationItem } from './NavigationItem/NavigationItem';

interface Props {
  model: NavigationViewModel;
}

const Navigation: React.FC<Props> = ({ model }) => {
  const { page } = model;
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);

  const toggleClass = useCallback(() => {
    setMobileMenuActive(!isMobileMenuActive);
  }, [isMobileMenuActive]);

  const [scrollNav, setScrollNav] = useState(false);

  const changeScrollNav = useCallback(() => {
    if (window.scrollY >= 90) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', changeScrollNav);
  }, []);

  return (
    <nav
      className={`container-fluid py-5 ${isMobileMenuActive ? 'navigation-mobile' : ''} ${
        scrollNav ? 'navigation navigation-bg' : 'navigation'
      } ${model.hasBackground ? 'bg-evergreen' : ''}`}>
      <div className="container-xxl px-5 navigation-container">
        <div className={`w-100 mobile-menu-container nav-mobile ${isMobileMenuActive ? 'd-block' : ''}  p-0`}>
          <div className="col-8 nav-logo">
            <CompanyLogo type={LogoType.SmallSand} />
          </div>

          <div className={`d-flex justify-content-end ${isMobileMenuActive ? 'col-4' : 'w-100'}`}>
            <HamburgerIcon onPress={toggleClass} />
          </div>
        </div>
        <div className="row nav-desktop">
          <div
            className={`col-2 col-xl-6 nav-logo-container ${scrollNav ? 'nav-logo-scroll' : ''} ${
              page === CurrentPage.Contact ? 'logo-visible' : ''
            }`}>
            <div className="nav-desktop">
              <CompanyLogo type={page === CurrentPage.Home ? LogoType.AnimatedSand : LogoType.FullSand} />
            </div>
          </div>
          <div className="col-10 col-xl-6 nav-desktop nav-items-container">
            <div className="h-100 d-flex flex-row align-items-center justify-content-between">
              <NavigationItem model={NavigationItemViewModel.getHomeLink(page, true)} />
              <NavigationItem model={NavigationItemViewModel.getProjectsLink(page, true)} />
              <NavigationItem model={NavigationItemViewModel.getServicesLink(page, true)} />
              <NavigationItem model={NavigationItemViewModel.getPesloLink(page, true)} />
              <NavigationItem model={NavigationItemViewModel.getContactLink(page, true)} />
              <NavigationItem model={NavigationItemViewModel.getBlogLink(page, true)} />
            </div>
          </div>
        </div>
        <div
          className={`flex-column bg-evergreen py-5 ${!isMobileMenuActive ? 'hide' : ''} ${
            scrollNav ? 'nav-menu-scroll' : ''
          }`}
          id="navbarToggleMobile">
          <NavigationItem model={NavigationItemViewModel.getHomeLink(page, true)} />
          <NavigationItem model={NavigationItemViewModel.getProjectsLink(page, true)} />
          <NavigationItem model={NavigationItemViewModel.getServicesLink(page, true)} />
          <NavigationItem model={NavigationItemViewModel.getPesloLink(page, true)} />
          <NavigationItem model={NavigationItemViewModel.getContactLink(page, true)} />
          <NavigationItem model={NavigationItemViewModel.getBlogLink(page, true)} />

          <div className="recent-project flex-column justify-content-center mt-4">
            <p className="h3 f-sunsetsand text-center">Recent project for ev.energy</p>
            <div className="d-flex justify-content-center">
              <ProjectCard model={ProjectCardViewModel.getProjectCard(ClientOption.EVEnergy, Color.Evergreen)} />
            </div>
          </div>

          <div className="mt-auto d-flex my-3 justify-content-between">
            <SocialIcon type={SocialType.Linkedin} />
            <SocialIcon type={SocialType.Twitter} />
            <SocialIcon type={SocialType.Facebook} />
            <SocialIcon type={SocialType.GitHub} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Navigation);
