import './ServiceIconContainer.scss';

import React from 'react';

import { IconType } from '../../models/icon';
import { IconContainerViewModel } from '../../models/icon-container';

import { ServiceIcon } from './ServiceIcon/ServiceIcon';

type Props = {
  model: IconContainerViewModel;
};

function renderIconList(icons: IconType[]) {
  return icons.map((item: IconType) => (
    <div key={`icon-container-${item}`} className="col-12 col-lg-6 justify-content-stretch">
      <ServiceIcon key={`icon-${item}`} type={item} />
    </div>
  ));
}

export const ServiceIconContainer: React.FC<Props> = ({ model }) => (
  <div
    className={`service-icon-main-container container-xxl d-flex flex-column justify-content-center align-items-center ${
      model.backgroundColor !== undefined ? `bg-${model.backgroundColor}` : ''
    }`}>
    <div className="service-icon-container d-flex flex-column justify-content-center pb-5">
      <div className="w-100 d-flex justify-content-center pb-5">
        <h2 className="h1 f-evergreen text-center icon-container-title">{model.title}</h2>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <p className="f-evergreen text-center icon-container-title">{model.subTitle}</p>
      </div>
      <div className="row justify-content-center icon-container-content">{renderIconList(model.icons)}</div>
    </div>
  </div>
);
