import './ServiceContainer.scss';

import React, { ReactElement } from 'react';

import { Color } from '../../models/color';
import { ServiceContainerViewModel } from '../../models/service';

import { getUnderscoreClasses } from '../../constants/underscore';

import { ImageContainer } from '../ImageContainer/ImageContainer';
import { Arrow } from '../Symbol/Arrow/Arrow';

import { ServiceCardContainer } from './ServiceCardContainer/ServiceCardContainer';

type Props = {
  model: ServiceContainerViewModel;
  children: ReactElement;
};

export const ServiceContainer: React.FC<Props> = ({ children, model }) => {
  function renderArrow(color: Color | undefined) {
    return color ? <Arrow color={color} /> : null;
  }

  return (
    <div>
      <div className="container-xxl bg-hover-sage p-5 position-relative">
        <div className="px-3 service-container">
          <ImageContainer model={model.image} />
          <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
            <div className="service-content-container text-center">
              <div className="d-flex my-4 text-banner-title">
                <h2
                  className={`h1 text-center ${
                    model.title.color ? `f-${model.title.color}` : ''
                  } ${getUnderscoreClasses(model.title.underscore)}`}>
                  {model.title.text}
                </h2>
              </div>

              {children}

              <div className="mt-5 d-inline-flex my-4 text-banner-title">
                <h3
                  className={`h3 text-center ${
                    model.subTitle.color ? `f-${model.subTitle.color}` : ''
                  } ${getUnderscoreClasses(model.subTitle.underscore)}`}>
                  {model.subTitle.text}
                </h3>
              </div>
            </div>
          </div>
        </div>

        {renderArrow(model.arrow)}
      </div>

      <div className="container-fluid px-3 justify-content-center py-5">
        <ServiceCardContainer model={model.cardContainer} />
      </div>
    </div>
  );
};
