import './Legal.scss';

import React from 'react';

import ReactMarkdown from 'react-markdown';

import { ContactCardContainer } from '../components/ContactContainer/ContactCardContainer';
import { Header } from '../components/Header/Header';

import { ContactCardContainerViewModel } from '../models/contact-card-container';
import { CurrentPage } from '../models/current-page';
import { HeaderViewModel } from '../models/header';

interface Props {
  page: CurrentPage;
  content: string;
  title: string;
}

export const Legal = ({ content, page, title }: Props) => (
  <div>
    <Header model={HeaderViewModel.getLegalHeader(page, title)} />
    <div className="legal-content-container">
      <div className="legal-content mx-5 my-5">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
    <ContactCardContainer model={ContactCardContainerViewModel.getMissionContainer(page)} />
  </div>
);
