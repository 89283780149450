import './index.scss';

import React from 'react';

import { ContactHeader } from '../ContactHeader';

export const ContactCalendar = () => (
  <div className="d-flex flex-column">
    <ContactHeader title="book a call" subtitle="Pick a time to chat, and you'll receive an invite in your calendar." />
    <div className="bg-paper mt-5 calendar-frame">
      <iframe
        className="w-100 h-100"
        title="Peslo Appointments"
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2LXKhdJnUaUHRBro9bW6sQ_KCfGF4wPUEN2i6BgpXHBo7D1Eyo_WSIQLSMv2pw-7gAAorGJuJE?gv=true"
      />
    </div>
  </div>
);
