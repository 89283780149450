import { ClientOption } from './client';
import { Color } from './color';
import { ImageExtension } from './image';
import { TextOption, TextViewModel } from './text';

export enum ProjectCardImage {
  TemplateEvergreen = 'project-template-evergreen',
  TemplateSunsetSand = 'project-template-sunsetsand',
  TemplateSea = 'project-templete-sea',
  Zilch = 'zilch',
  Cherryz = 'cherryz',
  PerchPeek = 'perchpeek',
  EVEnergy = 'ev-energy',
  Perception = 'perception',
  Wildhero = 'wildhero',
  CleanMe = 'cleanme'
}

export class ProjectCardViewModel {
  index: number;

  image: ProjectCardImage;

  extension: ImageExtension;

  retina: boolean;

  imageAlt: string;

  link: string;

  title: TextViewModel;

  subText: TextViewModel;

  borderColor: Color;

  iconColor: Color;

  navCard: boolean;

  iconImage?: string;

  rating: number;

  constructor(
    index: number,
    image: ProjectCardImage,
    extension: ImageExtension,
    retina: boolean,
    link: string,
    title: TextViewModel,
    subText: TextViewModel,
    borderColor: Color,
    iconColor: Color,
    navCard: boolean,
    rating: number,
    iconImage?: string
  ) {
    this.index = index;
    this.image = image;
    this.imageAlt = ProjectCardViewModel.getProjectImageAlt(this.image);
    this.extension = extension;
    this.retina = retina;
    this.link = link;
    this.title = title;
    this.subText = subText;
    this.borderColor = borderColor;
    this.iconColor = iconColor;
    this.navCard = navCard;
    this.rating = rating;
    this.iconImage = iconImage;
  }

  static getProjectCard(client: ClientOption, navColor: Color | undefined = undefined): ProjectCardViewModel {
    switch (client) {
      case ClientOption.ThreeSidedCube:
        return this.threeSidedCubeCard(navColor);
      case ClientOption.Cherryz:
        return this.cherryzCard(navColor);
      case ClientOption.Zilch:
        return this.zilchCard(navColor);
      case ClientOption.PerchPeek:
        return this.perchpeekCard(navColor);
      case ClientOption.EVEnergy:
        return this.evEnergyCard(navColor);
      case ClientOption.Perception:
        return this.perceptionCard(navColor);
      case ClientOption.Wildhero:
        return this.wildheroCard(navColor);
      case ClientOption.CleanMe:
        return this.cleanmeCard(navColor);
      default:
        throw new Error(`getProjectCard - unexpected client value '${client}' provided`);
    }
  }

  private static setColor(navColor: Color | undefined) {
    return navColor === Color.Evergreen ? Color.Paper : Color.Evergreen;
  }

  private static setIconColor(navColor: Color | undefined) {
    return navColor === Color.Paper ? Color.SunsetSand : Color.Paper;
  }

  private static threeSidedCubeCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.ThreeSidedCube,
      ProjectCardImage.TemplateEvergreen,
      ImageExtension.SVG,
      false,
      '/',
      new TextViewModel('3 Sided Cubed', color, TextOption.HeaderThree, undefined),
      new TextViewModel('Tech for Good - changing millions of lives for the better', color, TextOption.Body, undefined),
      color,
      this.setIconColor(navColor),
      !!navColor,
      5
    );
  }

  private static cherryzCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.Cherryz,
      ProjectCardImage.Cherryz,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/cherryz',
      new TextViewModel('Cherryz', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We helped Cherryz build a world-class shopping experience for people traditionally ignored by online retailers; delivering affordable quality products directly to their phone',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.8,
      'cherryz'
    );
  }

  private static zilchCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.Zilch,
      ProjectCardImage.Zilch,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/zilch',
      new TextViewModel('Zilch', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We delivered Tap and Pay to 3.5m+ customers across Apple Pay, Google Pay, and Samsung Pay; to help users access credit safely and responsibly',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.3,
      'zilch'
    );
  }

  private static perchpeekCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.PerchPeek,
      ProjectCardImage.PerchPeek,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/perchpeek',
      new TextViewModel('PerchPeek', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We built a comprehensive automated E2E test suite, ensuring delivery of a reliable and performant mobile app, so anyone can live anywhere',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.0,
      'perchpeek'
    );
  }

  private static evEnergyCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.EVEnergy,
      ProjectCardImage.EVEnergy,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/ev-energy',
      new TextViewModel('ev.energy', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We produced a reusable IOT EV charger solution to promote equitable access to EV charging; decreasing grid load & carbon intensity for a range of worldwide utility partners',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.5,
      'ev-energy'
    );
  }

  private static perceptionCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.Perception,
      ProjectCardImage.Perception,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/perception',
      new TextViewModel('Perception', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We built an app for drivers to view, save, and share their car footage; helping drivers in the event of an accident or incident to promptly preserve evidence',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      5,
      'perception'
    );
  }

  private static wildheroCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.Wildhero,
      ProjectCardImage.Wildhero,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/wildhero-by-treecard',
      new TextViewModel('Wildhero by Treecard', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We developed an MVP for Wildhero, allowing users to plant trees by reading their emails',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.6,
      'wildhero'
    );
  }

  private static cleanmeCard(navColor: Color | undefined): ProjectCardViewModel {
    const color = this.setColor(navColor);

    return new ProjectCardViewModel(
      ClientOption.CleanMe,
      ProjectCardImage.CleanMe,
      ImageExtension.JPG,
      true,
      'https://blog.peslostudios.com/projects/clean-me-marketplace',
      new TextViewModel('Clean Me Marketplace', color, TextOption.HeaderThree, undefined),
      new TextViewModel(
        'We worked with Clean Me to deliver their platform, and provide strong foundations for future growth',
        color,
        TextOption.Body,
        undefined
      ),
      color,
      this.setIconColor(navColor),
      !!navColor,
      4.2,
      'cleanme'
    );
  }

  private static getProjectImageAlt(type: ProjectCardImage): string {
    switch (type) {
      case ProjectCardImage.Zilch:
        return 'Screenshots of the Zilch mobile app';
      case ProjectCardImage.Cherryz:
        return 'Screenshots of the Cherryz mobile app';
      case ProjectCardImage.PerchPeek:
        return 'Screenshots of the PerchPeek mobile app';
      case ProjectCardImage.EVEnergy:
        return 'Screenshots of the ev.energy mobile app';
      case ProjectCardImage.Perception:
        return 'Screenshots of the Perception mobile app';
      default:
        return 'Complete generated of a phone template';
    }
  }
}
