import './index.scss';

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '../../../../components/Button/Button';

import { ButtonStyleType, ButtonType, ButtonViewModel } from '../../../../models/button';
import { FieldName, FormFieldType } from '../../../../models/form-field';

import { analytics } from '../../../../utils/analytics';
import { validateEmail, validatePhone } from '../../../../utils/validation';

import { ContactHeader } from '../ContactHeader';

import { FormField } from './FormField';

type FormState = { [key: string]: boolean | undefined };
enum FormStatus {
  Idle,
  Loading,
  Success,
  Error
}

export const ContactForm = () => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.Idle);

  // All fields start off undefined, they haven't yet been modified
  const [validationState, setValidationState] = useState<FormState>({
    [FieldName.Name]: undefined,
    [FieldName.Email]: undefined,
    [FieldName.Phone]: undefined,
    [FieldName.Message]: undefined
  });

  // All fields start off untouched
  const [touchedState, setTouchedState] = useState<FormState>({
    [FieldName.Name]: false,
    [FieldName.Email]: false,
    [FieldName.Phone]: false,
    [FieldName.Message]: false
  });

  const validateInput = useCallback(
    (value: string, fieldType: FormFieldType, fieldName: FieldName) => {
      switch (fieldType) {
        case FormFieldType.Text:
        case FormFieldType.TextArea:
          setValidationState({
            ...validationState,
            [fieldName]: value.trim().length >= 2
          });
          break;

        case FormFieldType.Email:
          setValidationState({
            ...validationState,
            [fieldName]: validateEmail(value)
          });
          break;

        case FormFieldType.Telephone:
          setValidationState({
            ...validationState,
            [fieldName]: validatePhone(value)
          });
          break;

        default:
          setValidationState({
            ...validationState,
            [fieldName]: true
          });
          break;
      }
    },
    [validationState]
  );

  const formCompleted = useMemo(
    () =>
      Object.values(validationState).every(element => !!element) &&
      Object.values(touchedState).every(element => !!element),
    [validationState, touchedState]
  );

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FormStatus.Loading);
    // eslint-disable-next-line
    // @ts-ignore
    const formElements = [...event.currentTarget.elements].filter(elem => elem.name !== 'bot-field');

    const filledOutElements = formElements
      .map(element => `${encodeURIComponent(element.name)}=${encodeURIComponent(element.value)}`)
      .join('&');

    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: filledOutElements
      });

      setStatus(response.ok ? FormStatus.Success : FormStatus.Error);

      if (response.ok) {
        analytics.completedContactForm();
      }
    } catch (error) {
      setStatus(FormStatus.Error);
    }
  }, []);

  return (
    <div className="mb-5">
      <div>
        <div className="d-flex flex-column">
          <ContactHeader
            title="write to us"
            subtitle="Prefer to leave us an email? Use the form below and we'll be in touch!"
          />
        </div>
        <form className="mt-5" onSubmit={onSubmit}>
          <input type="hidden" name="bot-field" value="" />
          <input type="hidden" name="form-name" value="contact" />
          <FormField
            onTouched={() => {
              setTouchedState({ ...touchedState, [FieldName.Name]: true });
            }}
            valid={validationState[FieldName.Name]}
            onChanged={validateInput}
            name={FieldName.Name}
            label="Name"
            placeholder="Your name"
            type={FormFieldType.Text}
            errorMessage="Please enter your name"
          />
          <FormField
            onTouched={() => {
              setTouchedState({ ...touchedState, [FieldName.Email]: true });
            }}
            valid={validationState[FieldName.Email]}
            onChanged={validateInput}
            name={FieldName.Email}
            label="Work email"
            placeholder="you@example.com"
            type={FormFieldType.Email}
            errorMessage="Please enter a valid email address"
          />
          <FormField
            onTouched={() => {
              setTouchedState({ ...touchedState, [FieldName.Phone]: true });
            }}
            valid={validationState[FieldName.Phone]}
            onChanged={validateInput}
            name={FieldName.Phone}
            label="Phone number"
            placeholder="+44 1234 567890"
            type={FormFieldType.Telephone}
            errorMessage="Please enter a valid phone number (including the country code)"
          />
          <FormField
            onTouched={() => {
              setTouchedState({ ...touchedState, [FieldName.Message]: true });
            }}
            valid={validationState[FieldName.Message]}
            onChanged={validateInput}
            name={FieldName.Message}
            label="How can we help?"
            placeholder=""
            type={FormFieldType.TextArea}
            errorMessage="Please enter your message"
          />
          <div data-netlify-recaptcha="true" />
          <div className="w-100 mt-5 d-flex justify-content-center">
            <Button
              model={new ButtonViewModel('Send', undefined, ButtonStyleType.Primary, ButtonType.Submit)}
              loading={status === FormStatus.Loading}
              disabled={!formCompleted}
            />
          </div>
        </form>
        {status === FormStatus.Success && (
          <div className="mt-5 py-3 px-4 success-message">
            <img className="me-3" src="images/icons/tick.svg" alt="Tick in a green circle" />
            <p>Your message has been successfully sent!</p>
          </div>
        )}
        {status === FormStatus.Error && (
          <div className="mt-5 py-3 px-4 error-message">
            <img className="me-3" src="images/icons/warning.svg" alt="Exclamation mark in a red circle" />
            <p>There was an error submitting your details, please try again later</p>
          </div>
        )}
      </div>
    </div>
  );
};
