import { Color } from './color';
import { ImageExtension } from './image';
import { ImageContainerViewModel } from './image-container';
import { Position } from './position';
import { ServiceCardContainerViewModel } from './service-card';
import { TextOption, TextViewModel } from './text';

export class ServiceContainerViewModel {
  public backgroundColor: Color;

  public image: ImageContainerViewModel;

  public title: TextViewModel;

  public subTitle: TextViewModel;

  public arrow: Color | undefined;

  public cardContainer: ServiceCardContainerViewModel;

  constructor(
    backgroundColor: Color,
    image: ImageContainerViewModel,
    title: TextViewModel,
    subTitle: TextViewModel,
    arrow: Color | undefined,
    cardContainer: ServiceCardContainerViewModel
  ) {
    this.backgroundColor = backgroundColor;
    this.image = image;
    this.title = title;
    this.subTitle = subTitle;
    this.arrow = arrow;
    this.cardContainer = cardContainer;
  }

  static getServiceContent(): ServiceContainerViewModel {
    return new ServiceContainerViewModel(
      Color.HoverSage,
      new ImageContainerViewModel(
        Position.Start,
        Position.End,
        '/images/service/service-grass',
        ImageExtension.JPG,
        true,
        'Camera at level with grass with a person standing, with their lower half showing and phone in hand',
        Color.Evergreen
      ),
      new TextViewModel('Improving our world, one app at a time', Color.Evergreen, TextOption.HeaderOne, Color.Sage),
      new TextViewModel('Explore how we do it', Color.Evergreen, TextOption.HeaderThree, undefined),
      Color.SunsetSand,
      ServiceCardContainerViewModel.getServiceCardContainer()
    );
  }
}
