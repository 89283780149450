import { ContactCardViewModel } from './contact-card';
import { CurrentPage } from './current-page';

export class ContactCardContainerViewModel {
  currentPage: CurrentPage;

  titles: string[] | undefined;

  contacts: ContactCardViewModel[];

  constructor(currentPage: CurrentPage, contacts: ContactCardViewModel[], titles: string[] | undefined) {
    this.currentPage = currentPage;
    this.titles = titles;
    this.contacts = contacts;
  }

  static getContactPageContainer(currentPage: CurrentPage) {
    const model = new ContactCardContainerViewModel(currentPage, [], undefined);
    model.contacts.push(ContactCardViewModel.getQuestionsCard(currentPage));

    return model;
  }

  static getMissionContainer(currentPage: CurrentPage) {
    const model = new ContactCardContainerViewModel(currentPage, [], ["let's improve the planet", 'together']);
    model.contacts.push(ContactCardViewModel.getProjectCard(currentPage));
    model.contacts.push(ContactCardViewModel.getQuestionsCard(currentPage));

    return model;
  }
}
