import React from 'react';

import { Helmet } from 'react-helmet-async';

interface Props {
  title: string;
}

const HelmetMetadata = ({ title }: Props) => (
  <Helmet>
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta property="og:title" content={title} />
  </Helmet>
);

export default HelmetMetadata;
