import './Header.scss';

import React from 'react';

import { Color } from '../../models/color';
import { CurrentPage } from '../../models/current-page';
import { HeaderViewModel } from '../../models/header';
import { LogoType } from '../../models/logo';
import { NavigationViewModel } from '../../models/navigation';
import { TextViewModel } from '../../models/text';

import { Button } from '../Button/Button';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import Navigation from '../Navigation/Navigation';

type Props = {
  model: HeaderViewModel;
};

export const Header: React.FC<Props> = ({ model }) => {
  const { currentPage } = model;

  function getNavigation(): NavigationViewModel {
    return new NavigationViewModel(false, currentPage);
  }

  function getSubtitleVisibility(subTitle: TextViewModel | null) {
    return !subTitle ? `hide` : '';
  }

  const buttons = model.buttons.map(button => (
    <div className="px-1" key={button.text}>
      <Button model={button} />
    </div>
  ));

  const url = 'images/header-bg';
  const extension = 'jpg';
  const backgroundSrcSet = `${url}-1x.${extension} 1x, ${url}-2x.${extension} 2x, ${url}-3x.${extension} 3x`;
  const backgroundSrc = `${url}.${extension}`;

  function loadButtons() {
    if (buttons?.length > 0) {
      return (
        <div className="header-btns col-xl-6 col-0 flex-row justify-content-end align-items-center">{buttons}</div>
      );
    }

    return undefined;
  }

  function loadImage() {
    if (model.image) {
      return (
        <div className="position-relative header-image col-xl-6 col-0 d-flex flex-row justify-content-center align-items-center">
          <img className="position-absolute" src={model.image.url} alt={model.image.alt} />
        </div>
      );
    }

    return undefined;
  }

  function getHeaderLength(page: CurrentPage) {
    switch (page) {
      case CurrentPage.Home:
        return 'home';
      case CurrentPage.Services:
        return 'services';
      case CurrentPage.Projects:
        return 'project';
      case CurrentPage.Contact:
        return 'contact';
      default:
        return '';
    }
  }

  return (
    <div className="bg-evergreen p-0 container-fluid">
      <div className="header-container">
        <img
          aria-label="Header background image"
          className="header-background-image"
          srcSet={backgroundSrcSet}
          src={backgroundSrc}
          alt="A group of hills with a valley between between them"
        />
        <Navigation model={getNavigation()} />
        <div className="container-xxl px-5 position-relative">
          <div className="header-text-container">
            <div className="w-100 justify-content-center header-logo">
              <CompanyLogo type={currentPage === CurrentPage.Home ? LogoType.AnimatedSand : LogoType.FullSand} />
            </div>
            <div className="w-100 pt-5 header-hero-sub-text-container">
              <p
                className={`h3 f-${model.subTitle?.color ?? Color.SunsetSand} ${getSubtitleVisibility(
                  model.subTitle
                )}`}>
                {model.subTitle?.text}
              </p>
            </div>
            <div className="w-100 pt-2 pb-5">
              <div className="row header-hero-content-container">
                <div
                  className={`col-xl-6 col-12 d-flex align-items-center header-hero-text-container ${getHeaderLength(
                    model.currentPage
                  )}`}>
                  <h1 className="bracket-content">{model.title.text}</h1>
                </div>
                {loadButtons()}
                {loadImage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
