import { Color } from './color';
import { IconType } from './icon';

export class IconContainerViewModel {
  public title: string;

  public subTitle: string;

  public icons: IconType[];

  public backgroundColor: Color | undefined;

  constructor(title: string, subTitle: string, icons: IconType[], backgroundColor: Color | undefined) {
    this.title = title;
    this.subTitle = subTitle;
    this.icons = icons;
    this.backgroundColor = backgroundColor;
  }

  static getServicesModel() {
    return new IconContainerViewModel(
      'Not sure what you need in your mobile app?',
      "There's nothing in mobile we haven't worked with, and the niche technologies within mobile are our speciality. We can utilise any feature and capability, working with you to decide what's best. Where necessary, we'll liaise with you and your technical partners to ensure all requirements and guidelines are met",
      this.getServicesIconList(),
      Color.HoverSage
    );
  }

  private static getServicesIconList() {
    return [
      IconType.PushNotification,
      IconType.InAppPurchases,
      IconType.AppleGoogleWallet,
      IconType.MapService,
      IconType.ConnectingAPIs,
      IconType.ECommerce,
      IconType.MultiLingual,
      IconType.Accounts,
      IconType.Gamification,
      IconType.InAppProvisioning,
      IconType.Bluetooth,
      IconType.Video,
      IconType.EVSE,
      IconType.IOT
    ];
  }

  static getPesloModel() {
    return new IconContainerViewModel(
      'Why Peslo?',
      "We're experts in our field - tackling some of the most complex projects within mobile. We're comfortable with some of the most niche technologies in mobile - from Tap and Pay to Bluetooth, offline mapping to video streaming - nothing is too big of a challenge. We believe in delivering positive impact, and we do the right thing as that's the only option - why compromise on things so important?",
      this.getPesloIconList(),
      Color.HoverSage
    );
  }

  private static getPesloIconList() {
    return [IconType.PassionForOurWorld, IconType.UserFirst, IconType.DeliveringImpact];
  }
}
