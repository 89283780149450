import { isContactPage } from '../utils/currentPage';

import { links } from '../constants/links';

import { ButtonStyleType, ButtonType, ButtonViewModel } from './button';
import { Color } from './color';
import { CurrentPage } from './current-page';

export class ContactCardViewModel {
  imageUrl: string;

  imageAlt: string;

  title: string;

  text: string;

  button: ButtonViewModel;

  background: Color | undefined;

  currentPage: CurrentPage;

  borderRadius: number;

  constructor(
    imageUrl: string,
    imageAlt: string,
    title: string,
    text: string,
    button: ButtonViewModel,
    background: Color | undefined,
    currentPage: CurrentPage,
    borderRadius: number
  ) {
    this.imageUrl = imageUrl;
    this.imageAlt = imageAlt;
    this.title = title;
    this.text = text;
    this.button = button;
    this.background = background;
    this.currentPage = currentPage;
    this.borderRadius = borderRadius;
  }

  static getProjectCard(currentPage: CurrentPage) {
    const displayingOnContactPage = isContactPage(currentPage);

    return new ContactCardViewModel(
      '/images/contact-project.svg',
      'Sketch of person siting at a desk with a laptop',
      'Got a project for us?',
      displayingOnContactPage ? "Let's improve our world together, reach out!" : "We'd love to help you, reach out!",
      new ButtonViewModel('Hire Us', links.emailHey, ButtonStyleType.Primary, ButtonType.Link),
      displayingOnContactPage ? undefined : Color.HoverSage,
      currentPage,
      displayingOnContactPage ? 0 : 4
    );
  }

  static getQuestionsCard(currentPage: CurrentPage) {
    const displayingOnContactPage = isContactPage(currentPage);

    return new ContactCardViewModel(
      '/images/contact-questions.svg',
      'Sketch of two people',
      'any questions for us?',
      displayingOnContactPage ? "We're more than happy to chat, get in touch!" : "We'd love to chat, get in touch!",
      new ButtonViewModel('Say Hey', links.emailHey, ButtonStyleType.Primary, ButtonType.Link),
      Color.HoverSage,
      currentPage,
      displayingOnContactPage ? 0 : 4
    );
  }
}
