import { Color } from './color';

export class StepViewModel {
  public stepBackgroundColor: Color;

  public titleColor: Color;

  public textColor: Color;

  public step: number;

  public stepContent: string;

  constructor(stepBackgroundColor: Color, titleColor: Color, textColor: Color, step: number, stepContent: string) {
    this.stepBackgroundColor = stepBackgroundColor;
    this.titleColor = titleColor;
    this.textColor = textColor;
    this.step = step;
    this.stepContent = stepContent;
  }

  static pesloProcessSteps(stepBackgroundColor: Color, secondaryColor: Color, textColor: Color): StepViewModel[] {
    const steps: StepViewModel[] = [];
    steps.push(
      new StepViewModel(
        stepBackgroundColor,
        secondaryColor,
        textColor,
        1,
        `You've got your innovative concept, you've pictured the future - and you know that you'll change the world. It's time to reach out to Peslo - we'll schedule a chat and take the time to understand your company & vision.`
      )
    );
    steps.push(
      new StepViewModel(
        stepBackgroundColor,
        secondaryColor,
        textColor,
        2,
        `We work closely with you in an investigation phase; that allows us to understand your objectives, collect requirements, identify risks, plan resources, ensure feasibility, establish performance baselines, and build an accurate quotation and timeline to prevent any surprises later on.`
      )
    );
    steps.push(
      new StepViewModel(
        stepBackgroundColor,
        secondaryColor,
        textColor,
        3,
        `We'll work together on producing designs, and upon sign off we'll schedule development to meet your deadlines. We work following Agile, with consistent and regular delivery of functionality. At every step of the way, we'll keep you updated and informed.`
      )
    );
    steps.push(
      new StepViewModel(
        stepBackgroundColor,
        secondaryColor,
        textColor,
        4,
        `We'll deliver the project in agreed phases, facilitating and building alongside your feedback to ensure your vision is held center. We'll facilitate testing, and ensure you're as happy as possible with the end product.`
      )
    );
    steps.push(
      new StepViewModel(
        stepBackgroundColor,
        secondaryColor,
        textColor,
        5,
        `We'll guide you through getting the app onto the App Store and Google Play Store, and get your world-class app released to your customers. But we don't stop here. We'll work with you to deliver further updates & improvements so you can continue to change the world.`
      )
    );

    return steps;
  }
}
