import { ButtonStyleType, ButtonType, ButtonViewModel } from './button';
import { Color } from './color';
import { CurrentPage } from './current-page';
import { TextOption, TextViewModel } from './text';

export interface HeaderImageViewModel {
  url: string;
  alt: string;
}

export class HeaderViewModel {
  title: TextViewModel;

  subTitle: TextViewModel | null;

  currentPage: CurrentPage;

  buttons: ButtonViewModel[];

  image: HeaderImageViewModel | undefined;

  constructor(title: TextViewModel, currentPage: CurrentPage, subTitle: TextViewModel | null = null) {
    this.title = title;
    this.subTitle = subTitle;
    this.currentPage = currentPage;
    this.buttons = [];
  }

  public static getHomeHeader(currentPage: CurrentPage): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel(
        'world-class software for world-changing startups',
        Color.Paper,
        TextOption.HeaderOne,
        undefined
      ),
      currentPage,
      new TextViewModel('Full Stack Digital Product Agency', Color.SunsetSand, TextOption.HeaderOne, undefined)
    );
    model.buttons.push(
      new ButtonViewModel('Book your free call', '/contact', ButtonStyleType.Secondary, ButtonType.Link)
    );

    return model;
  }

  public static getProjectsHeader(currentPage: CurrentPage): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel('The Peslo collection', Color.Paper, TextOption.HeaderOne, undefined),
      currentPage
    );

    model.buttons.push(new ButtonViewModel('Hire Us', '/contact', ButtonStyleType.Secondary, ButtonType.Link));

    return model;
  }

  public static getServicesHeader(currentPage: CurrentPage): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel('What we can offer you', Color.Paper, TextOption.HeaderOne, undefined),
      currentPage
    );

    model.buttons.push(new ButtonViewModel('Hire Us', '/contact', ButtonStyleType.Secondary, ButtonType.Link));
    model.buttons.push(new ButtonViewModel('View Projects', '/projects', ButtonStyleType.Tertiary, ButtonType.Link));

    return model;
  }

  public static getPesloHeader(currentPage: CurrentPage): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel('What is Peslo?', Color.Paper, TextOption.HeaderOne, undefined),
      currentPage
    );

    model.buttons.push(new ButtonViewModel('Hire Us', '/contact', ButtonStyleType.Secondary, ButtonType.Link));

    return model;
  }

  public static getContactHeader(currentPage: CurrentPage): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel('Got a project for us?', Color.Paper, TextOption.HeaderOne, undefined),
      currentPage
    );

    model.image = {
      url: 'images/contact-project-sage.svg',
      alt: 'Sketch of person siting at a desk with a laptop'
    };

    return model;
  }

  public static getLegalHeader(currentPage: CurrentPage, title: string): HeaderViewModel {
    const model = new HeaderViewModel(
      new TextViewModel(title, Color.Paper, TextOption.HeaderOne, undefined),
      currentPage
    );

    return model;
  }
}
