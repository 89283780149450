import { Color } from './color';

export enum QuoteBy {
  Ryan = 'Ryan',
  RyanReProjects = 'RyanReProjects',
  RyanReContact = 'RyanReContact',
  PerchPeek = 'Dave'
}

export class QuoteViewModel {
  imageUrl: string;

  imageAlt: string;

  quote: string;

  name: string;

  position: string;

  background: Color | undefined;

  quoteColor: Color;

  constructor(
    url: string,
    alt: string,
    quote: string,
    name: string,
    position: string,
    background: Color | undefined,
    quoteColor: Color = Color.SunsetSand
  ) {
    this.imageUrl = `images/quote/${url}`;
    this.imageAlt = alt;
    this.quote = quote;
    this.name = name;
    this.position = position;
    this.background = background;
    this.quoteColor = quoteColor;
  }

  static getQuoteFor(quoteBy: QuoteBy, background: Color | undefined) {
    switch (quoteBy) {
      case QuoteBy.PerchPeek:
        return new QuoteViewModel(
          'ryan',
          `${quoteBy} Logo`,
          'orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. commodo consequat.',
          quoteBy,
          'Dave, PerchPeek',
          background
        );

      case QuoteBy.Ryan:
        return new QuoteViewModel(
          'ryan',
          `Picture of ${quoteBy}`,
          "we're an app development consultancy with purpose, focusing on the world we share...",
          quoteBy,
          'Founder, Peslo',
          background
        );
      case QuoteBy.RyanReContact:
        return new QuoteViewModel(
          'ryan',
          `Picture of ${quoteBy}`,
          'Let us help you develop your next mobile app to improve our planet.',
          'Ryan',
          'Founder, Peslo',
          background
        );
      case QuoteBy.RyanReProjects:
      default:
        return new QuoteViewModel(
          'ryan',
          `Picture of ${quoteBy}`,
          "we're proud of our positive impact on society, and we're always looking for how we can do more",
          'Ryan',
          'Founder, Peslo',
          background,
          Color.Sage
        );
    }
  }
}
