export enum ButtonStyleType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export enum ButtonType {
  Link = 'link',
  Submit = 'submit'
}
export class ButtonViewModel {
  text: string;

  link: string | undefined;

  styleType: ButtonStyleType;

  type: ButtonType;

  constructor(text: string, link: string | undefined, styleType: ButtonStyleType, type: ButtonType = ButtonType.Link) {
    this.text = text;
    this.link = link;
    this.styleType = styleType;
    this.type = type;
  }
}
