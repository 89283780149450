import { Color } from './color';

export enum IconType {
  PushNotification,
  InAppPurchases,
  AppleGoogleWallet,
  MapService,
  ConnectingAPIs,
  ECommerce,
  MultiLingual,
  Accounts,
  Gamification,
  InAppProvisioning,
  PassionForOurWorld,
  UserFirst,
  DeliveringImpact,
  Bluetooth,
  Video,
  EVSE,
  IOT
}

export class IconViewModel {
  public backgroundColor: Color | undefined;

  public url: string;

  public alt: string;

  public text: string;

  public textColor: Color;

  public textBackgroundColor: Color | undefined;

  constructor(
    backgroundColor: Color | undefined,
    url: string,
    alt: string,
    text: string,
    textColor: Color,
    textBackgroundColor: Color | undefined
  ) {
    this.backgroundColor = backgroundColor;
    this.url = url;
    this.alt = alt;
    this.text = text;
    this.textColor = textColor;
    this.textBackgroundColor = textBackgroundColor;
  }

  public static getIconByType(type: IconType): IconViewModel {
    switch (type) {
      case IconType.PushNotification:
        return this.getPushNotificationIcon();
      case IconType.InAppPurchases:
        return this.getInAppPurchaseIcon();
      case IconType.AppleGoogleWallet:
        return this.getAppleGoogleWalletIcon();
      case IconType.MapService:
        return this.getMapServiceIcon();
      case IconType.ConnectingAPIs:
        return this.getConnectingApisIcon();
      case IconType.ECommerce:
        return this.getECommerceIcon();
      case IconType.MultiLingual:
        return this.getMultiLingualIcon();
      case IconType.Accounts:
        return this.getAccountsIcon();
      case IconType.Gamification:
        return this.getGamificationIcon();
      case IconType.InAppProvisioning:
        return this.getInAppProvisioning();
      case IconType.PassionForOurWorld:
        return this.getPassionForOurWorldIcon();
      case IconType.UserFirst:
        return this.getUserFirstIcon();
      case IconType.DeliveringImpact:
        return this.getDeliveringImpactIcon();
      case IconType.Bluetooth:
        return this.getBluetoothIcon();
      case IconType.Video:
        return this.getVideoPlayerIcon();
      case IconType.EVSE:
        return this.getEVSEIcon();
      case IconType.IOT:
        return this.getIOTDevicesIcon();
      default:
        throw new Error(`getIconByType - missing icon for ${type}`);
    }
  }

  private static getPushNotificationIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'ding-ding',
      'Notification bell',
      'Push Notifications',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getInAppPurchaseIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'in-app-purchase',
      'Dollar sign above a open hand',
      'In-App Purchases',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getAppleGoogleWalletIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'apple-google-wallet',
      'Wallet',
      'Apple & Google Wallet',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getMapServiceIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'map-services',
      'Map with pin',
      'Maps & Geolocation',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getConnectingApisIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'connecting-apis',
      'Two paper clips connected together',
      'APIs & Networking',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getECommerceIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'e-commence',
      'Shopping cart moving at speed',
      'E-Commerce',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getMultiLingualIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'multi-lingual',
      'Book with the letter A on on side and Japanese character on the other side',
      'Multi-Lingual',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getAccountsIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'accounts',
      'Two people',
      'Accounts & SSO',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getGamificationIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'gamification',
      'A trophy with a star on it',
      'Gamification',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getInAppProvisioning(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'in-app-provisioning',
      'A credit card',
      'In-App Provisioning / Tap and Pay',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getPassionForOurWorldIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'world',
      'Global',
      'Passion for our planet, leaving it better than we found it',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getUserFirstIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'crown',
      'Crown',
      'User-first, and user-driven',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getDeliveringImpactIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'shield',
      'Shield with a tick on it',
      'Delivering positive impact, changing the world',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getBluetoothIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'bluetooth',
      'Bluetooth icon',
      'Bluetooth',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getVideoPlayerIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'video',
      'A video camera',
      'Video & Streaming',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getEVSEIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'evse',
      'A charging cable',
      'Car charger integrations',
      Color.SunsetSand,
      Color.Evergreen
    );
  }

  private static getIOTDevicesIcon(): IconViewModel {
    return new IconViewModel(
      Color.LightEvergreen,
      'iot',
      'A central device with multiple nodes',
      'IOT Devices',
      Color.SunsetSand,
      Color.Evergreen
    );
  }
}
