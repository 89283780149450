import { Color } from './color';
import { ImageExtension } from './image';
import { Position } from './position';

export class ImageContainerViewModel {
  topCornerPosition: Position;

  bottomCornerPosition: Position;

  url: string;

  extension: ImageExtension;

  retina: boolean;

  alt: string;

  color: Color;

  constructor(
    topCornerPosition: Position,
    bottomCornerPosition: Position,
    url: string,
    extension: ImageExtension,
    retina: boolean,
    alt: string,
    color: Color
  ) {
    this.topCornerPosition = topCornerPosition;
    this.bottomCornerPosition = bottomCornerPosition;
    this.url = url;
    this.extension = extension;
    this.retina = retina;
    this.alt = alt;
    this.color = color;
  }

  static getImageContainer(
    url: string,
    extension: ImageExtension,
    retina: boolean,
    alt: string,
    topCornerPosition: Position,
    bottomCornerPosition: Position,
    color: Color = Color.Evergreen
  ) {
    return new ImageContainerViewModel(topCornerPosition, bottomCornerPosition, url, extension, retina, alt, color);
  }
}
