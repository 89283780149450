import './Quote.scss';

import React from 'react';

import { QuoteViewModel } from '../../models/quote';
import { QuoteIconViewModel } from '../../models/quote-icon';

import { QuoteIcon } from '../Symbol/QuoteIcon/QuoteIcon';

type Props = {
  model: QuoteViewModel;
};

export const Quote: React.FC<Props> = ({ model }) => {
  const { background, imageAlt, imageUrl, name, position, quote, quoteColor } = model;

  const srcset = `${imageUrl}-1x.jpg 1x, ${imageUrl}-2x.jpg 2x, ${imageUrl}-3x.jpg 3x`;

  return (
    <div className={`w-100 py-5 px-3 quote-main-container ${background ? `bg-${background}` : ''}`}>
      <div className="container-fluid d-flex justify-content-center align-items-center py-5 my-5">
        <div className="row quote-container d-flex justify-content-center align-items-center">
          <div className="col-12 col-lg-2 align-self-start justify-content-start">
            <QuoteIcon model={QuoteIconViewModel.getStartQuote(quoteColor)} />
          </div>

          <div className="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center px-4">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <img className="quote-image" srcSet={srcset} src={imageUrl} alt={imageAlt} />
            </div>

            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <h2 className="f-dark-evergreen text-center text-lowercase pt-3 pb-4 px-2">{quote}</h2>
              <h3 className="f-evergreen text-center quote">
                {name} | {position}
              </h3>
            </div>
          </div>

          <div className="col-12 col-lg-2 d-flex align-self-end justify-content-end">
            <QuoteIcon model={QuoteIconViewModel.getEndQuote(quoteColor)} />
          </div>
        </div>
      </div>
    </div>
  );
};
