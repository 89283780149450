export enum FormFieldType {
  Hidden = 'hidden',
  Text = 'text',
  Telephone = 'tel',
  Email = 'email',
  TextArea = 'text-area'
}

export enum FieldName {
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  Message = 'message'
}
