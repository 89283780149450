import './SocialIcon.scss';

import React from 'react';

import { SocialType } from '../../models/social-icon';

import { links } from '../../constants/links';

type Props = {
  type: SocialType;
};

const getSocialLink = (type: SocialType) => {
  switch (type) {
    case SocialType.Linkedin:
      return links.linkedIn;
    case SocialType.Facebook:
      return links.facebook;
    case SocialType.Twitter:
      return links.twitter;
    case SocialType.GitHub:
      return links.github;
    default:
      throw new Error(`getSocialLink - invalid type '${type}' provided`);
  }
};

function getSocialImage(type: SocialType) {
  let url: string | undefined;

  switch (type) {
    case SocialType.Linkedin:
      url = 'linkedin';
      break;
    case SocialType.Facebook:
      url = 'facebook';
      break;
    case SocialType.Twitter:
      url = 'twitter';
      break;
    case SocialType.GitHub:
      url = 'github';
      break;
    default:
      url = undefined;
      break;
  }

  if (url !== undefined) {
    url = `/images/${url}.svg`;
  }

  return url;
}

function getSocialImageAlt(type: SocialType) {
  switch (type) {
    case SocialType.Linkedin:
      return 'Linkedin Icon';
    case SocialType.Facebook:
      return 'Facebook Logo';
    case SocialType.Twitter:
      return 'Twitter Logo';
    case SocialType.GitHub:
      return 'Github Logo';
    default:
      return undefined;
  }
}

export const SocialIcon: React.FC<Props> = ({ type }) => (
  <a className="d-flex justify-content-center align-items-center mx-2 social-icon" href={getSocialLink(type)}>
    <img src={getSocialImage(type)} alt={getSocialImageAlt(type)} />
  </a>
);
