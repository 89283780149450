import './ContactCard.scss';

import React from 'react';

import { ContactCardViewModel } from '../../models/contact-card';

import { isContactPage } from '../../utils/currentPage';

import { Button } from '../Button/Button';

type Props = {
  model: ContactCardViewModel;
};

export const ContactCard: React.FC<Props> = ({ model }) => {
  const onContactPage = isContactPage(model.currentPage);

  function getHeaderBasedOnPage() {
    if (onContactPage) {
      return <h2 className="h1 f-evergreen">{model.title}</h2>;
    }

    return <h3 className="h4 f-evergreen">{model.title}</h3>;
  }

  return (
    <div className={`col-12 col-xl-6 ${onContactPage ? 'px-0 col-xl-12' : ''}`}>
      <div className={`py-5 bg-${model.background} contact-card-${model.borderRadius}`}>
        <div className="w-100 text-center">
          <img className="img-fluid" src={model.imageUrl} alt={model.imageAlt} />
        </div>
        <div className="w-100 text-center py-4 pb-2">{getHeaderBasedOnPage()}</div>
        <div className="w-100 text-center contact-card-text px-2">
          <p>{model.text}</p>
        </div>
        <div className="w-100 text-center">
          <Button model={model.button} />
        </div>
      </div>
    </div>
  );
};
