import { CurrentPage } from './current-page';

export class NavigationViewModel {
  hasBackground: boolean;

  page: CurrentPage;

  constructor(hasBackground: boolean, page: CurrentPage) {
    this.hasBackground = hasBackground;
    this.page = page;
  }
}
