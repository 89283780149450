import React from 'react';

import { Client, ClientOption } from '../../models/client';

type Props = {
  clientOption: ClientOption;
};

export const ClientLogo: React.FC<Props> = ({ clientOption }) => {
  const client: Client = Client.getClientLogo(clientOption);

  return (
    <a href={client.link}>
      <img className="img-fluid" src={`/images/clients/${client.logo}.svg`} alt={`${client.name} Logo`} />
    </a>
  );
};
