import './QuoteIcon.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { QuoteIconViewModel } from '../../../models/quote-icon';

type Props = {
  model: QuoteIconViewModel;
};

export const QuoteIcon: React.FC<Props> = ({ model }) => {
  const { backgroundColor, xPosition, yPosition } = model;

  const quoteColor = backgroundColor === Color.Paper ? Color.SunsetSand : Color.Sage;

  return (
    <div className={`quote-container quote-${yPosition} w-100 d-flex`}>
      <div className={`quote p-3 d-flex justify-self-${xPosition} align-self-${yPosition}`}>
        <img className="quote-img" src={`images/quote-${quoteColor}.svg`} alt={`Quote ${yPosition}`} />
      </div>
    </div>
  );
};
