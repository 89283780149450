import React from 'react';

import { Header } from '../../components/Header/Header';
import HelmetMetadata from '../../components/HelmetMetadata/HelmetMetadata';
import { Quote } from '../../components/Quote/Quote';
import { ContactPageContent } from './components/ContactPageContent';

import { Color } from '../../models/color';
import { CurrentPage } from '../../models/current-page';
import { HeaderViewModel } from '../../models/header';
import { QuoteBy, QuoteViewModel } from '../../models/quote';

const currentPage: CurrentPage = CurrentPage.Contact;
const pageTitle = 'Contact | Peslo';

export const Contact = () => (
  <div>
    <HelmetMetadata title={pageTitle} />
    <Header model={HeaderViewModel.getContactHeader(currentPage)} />
    <ContactPageContent />
    <Quote model={QuoteViewModel.getQuoteFor(QuoteBy.RyanReContact, Color.LightSage)} />
  </div>
);
