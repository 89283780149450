import { ButtonViewModel } from './button';
import { Color } from './color';
import { TextViewModel } from './text';

export class TextBannerViewModel {
  public backgroundColor: Color;

  public header: TextViewModel | undefined;

  public subHeading: TextViewModel | undefined;

  public button: ButtonViewModel | undefined;

  constructor(
    backgroundColor: Color,
    header: TextViewModel,
    subHeading: TextViewModel | undefined,
    button: ButtonViewModel | undefined
  ) {
    this.backgroundColor = backgroundColor;
    this.header = header;
    this.subHeading = subHeading;
    this.button = button;
  }
}
