import {
  clutchBadge1Location,
  clutchBadge2Location,
  clutchBadge3Location,
  clutchBadgeAlt,
  clutchBadgeExtension,
  clutchBadgeURL
} from '../constants/clutch-badge';

import { ButtonStyleType, ButtonType, ButtonViewModel } from './button';
import { Color } from './color';
import { ImageExtension } from './image';
import { ImageBadgeViewModel } from './image-badge';
import { ImageContainerViewModel } from './image-container';
import { Position } from './position';

export class SideImageContainerViewModel {
  imagePosition: Position;

  title: string;

  underscore: boolean;

  underscoreColor: Color | undefined;

  text: string;

  button: ButtonViewModel | undefined;

  image: ImageContainerViewModel;

  subImages?: ImageBadgeViewModel[];

  constructor(
    imagePosition: Position,
    title: string,
    text: string,
    image: ImageContainerViewModel,
    subImages: ImageBadgeViewModel[] | undefined,
    button: ButtonViewModel | undefined,
    underscore: boolean,
    underscoreColor: Color | undefined
  ) {
    this.imagePosition = imagePosition;
    this.title = title;
    this.underscore = underscore;
    this.underscoreColor = underscoreColor;
    this.text = text;
    this.image = image;
    this.subImages = subImages;
    this.button = button;
  }

  public static getHomePageMission() {
    return this.getMission(Color.Evergreen);
  }

  public static getPesloMission() {
    return this.getMission(Color.SunsetSand);
  }

  private static getMission(color: Color) {
    return new SideImageContainerViewModel(
      Position.End,
      'Our mission',
      'We join forces with other sustainable tech companies to solve problems to improve our world. Peslo provides world-class software engineering, ethical UX & UI design, branding position & creation, and operational & customer success support.',
      new ImageContainerViewModel(
        Position.Start,
        Position.End,
        'images/mission',
        ImageExtension.JPG,
        true,
        'Blue birds in flight',
        color
      ),
      [
        ImageBadgeViewModel.getSubImageContainer(
          clutchBadge1Location,
          clutchBadgeExtension,
          false,
          clutchBadgeAlt,
          'clutch-badge',
          clutchBadgeURL
        ),
        ImageBadgeViewModel.getSubImageContainer(
          clutchBadge2Location,
          clutchBadgeExtension,
          false,
          clutchBadgeAlt,
          'clutch-badge',
          clutchBadgeURL
        ),
        ImageBadgeViewModel.getSubImageContainer(
          clutchBadge3Location,
          clutchBadgeExtension,
          false,
          clutchBadgeAlt,
          'clutch-badge',
          clutchBadgeURL
        )
      ],
      new ButtonViewModel('Contact', '/contact', ButtonStyleType.Primary, ButtonType.Link),
      true,
      Color.SunsetSand
    );
  }
}
