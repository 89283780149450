import * as amplitude from '@amplitude/analytics-browser';

const initialize = () => {
  amplitude.init('91392f3c413c57f12a2d65ee23a2d303', undefined, {
    defaultTracking: {
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: true
    }
  });
};

const completedContactForm = () => {
  amplitude.track('contact_form', {
    action: 'submitted'
  });
};

// TODO: tag outbound email links as lead gen
// TODO: on successful netlify form, send lead gen event

export const analytics = {
  initialize,
  completedContactForm
};
