import './index.scss';

import React, { useCallback } from 'react';

const activeStyle = 'bg-evergreen f-sage';
const inactiveStyle = 'f-evergreen ';

export type ContactPageSegmentIndex = 0 | 1;

export const ContactPagePicker = ({
  onSetSegment,
  segment
}: {
  segment: ContactPageSegmentIndex;
  onSetSegment: (segment: ContactPageSegmentIndex) => void;
}) => {
  const onOpenPickATime = useCallback(() => {
    onSetSegment(0);
  }, []);

  const onOpenWriteToUs = useCallback(() => {
    onSetSegment(1);
  }, []);

  return (
    <div className="contact-segment-picker bg-sage rounded p-1 mb-5">
      <div className="btn-group" role="group">
        <button
          type="button"
          onClick={onOpenPickATime}
          className={`btn ${segment === 0 ? activeStyle : inactiveStyle} rounded`}>
          BOOK A CALL
        </button>
        <div className="m-1" />
        <button
          type="button"
          onClick={onOpenWriteToUs}
          className={`btn ${segment === 1 ? activeStyle : inactiveStyle} rounded`}>
          WRITE TO US
        </button>
      </div>
    </div>
  );
};
