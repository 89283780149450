import './ClientContainer.scss';

import React from 'react';

import { ButtonViewModel } from '../../models/button';
import { ClientOption } from '../../models/client';
import { ClientContainerViewModel } from '../../models/client-container';

import { getUnderscoreClasses } from '../../constants/underscore';

import { Button } from '../Button/Button';
import { ClientLogo } from '../ClientLogo/ClientLogo';

type Props = {
  model: ClientContainerViewModel;
};

export const ClientContainer: React.FC<Props> = ({ model }) => {
  function renderClientLogo(client: ClientOption) {
    return (
      <div className="col-6 col-md-3 d-flex justify-content-center my-4">
        <ClientLogo clientOption={client} />
      </div>
    );
  }

  function renderClientContainerButton(button: ButtonViewModel | undefined) {
    if (!button) {
      return null;
    }

    return (
      <div className="w-100 d-flex justify-content-center pt-2 pb-5">
        <Button model={button} />
      </div>
    );
  }

  return (
    <div className={`container-xxl py-5 ${model.background ? `bg-${model.background}` : ''} `}>
      <div className="py-5">
        <div className="w-100 d-flex justify-content-center">
          <div className="d-inline-flex pb-5">
            <h2
              className={`client-container-header h1 text-lowercase text-center f-evergreen mb-0 ${getUnderscoreClasses(
                model.underScoreColor
              )}`}>
              We've worked with
            </h2>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="row client-logo-container justify-content-center align-items-center">
            {renderClientLogo(ClientOption.Wildhero)}
            {renderClientLogo(ClientOption.CleanMe)}
            {renderClientLogo(ClientOption.EVEnergy)}
            {renderClientLogo(ClientOption.Zilch)}
            {renderClientLogo(ClientOption.AndDigital)}
            {renderClientLogo(ClientOption.PerchPeek)}
            {renderClientLogo(ClientOption.Cherryz)}
            {renderClientLogo(ClientOption.Perception)}
            {renderClientLogo(ClientOption.ThreeSidedCube)}
          </div>
        </div>
        {renderClientContainerButton(model.button)}
      </div>
    </div>
  );
};
