import './ContactCardContainer.scss';

import React from 'react';

import { Color } from '../../models/color';
import { ContactCardViewModel } from '../../models/contact-card';
import { ContactCardContainerViewModel } from '../../models/contact-card-container';

import { isContactPage } from '../../utils/currentPage';

import { getUnderscoreClasses } from '../../constants/underscore';

import { ContactCard } from '../ContactCard/ContactCard';

type Props = {
  model: ContactCardContainerViewModel;
};

export const ContactCardContainer: React.FC<Props> = ({ model }) => {
  const { currentPage, titles } = model;
  const onContactPage = isContactPage(currentPage);

  function classNameForTitle() {
    return titles != null && titles?.length > 0 ? '' : 'hide';
  }

  function generateContactTitle(title: string, key: number) {
    return <span key={`contact-card-title-${key}`}>{title}</span>;
  }

  function generateContainerTitleContainer() {
    const html: JSX.Element[] = [];

    if (titles != null && titles?.length > 0) {
      titles.forEach((title, index) => {
        if (index !== titles.length - 1) {
          html.push(generateContactTitle(title, index));
        } else {
          html.push(
            <span key={`contact-card-with-underscore-${title}`} className="contact-card-underscore-container">
              <span className={`d-inline-flex ${getUnderscoreClasses(Color.SunsetSand)}`}>{title}</span>
            </span>
          );
        }
      });
    }

    return html;
  }

  function loadProjectCard() {
    if (!onContactPage) {
      return <ContactCard model={ContactCardViewModel.getProjectCard(currentPage)} />;
    }

    return undefined;
  }

  return (
    <div className={`container-xxl ${onContactPage ? 'p-0 contact-card-container' : 'py-5'}`}>
      <div className={`${onContactPage ? '' : 'pt-lg-5'} d-flex row justify-content-center ${classNameForTitle()}`}>
        <div className={`col-12 col-xl-8 d-inline-flex flex-column justify-content-center ${classNameForTitle()}`}>
          <h2 className="text-center m-0 h1 text-lowercase f-black">{generateContainerTitleContainer()}</h2>
        </div>
      </div>
      <div className={`row contact-card-items ${onContactPage ? 'p-0 m-0' : ''}`}>
        {loadProjectCard()}
        <ContactCard model={ContactCardViewModel.getQuestionsCard(currentPage)} />
      </div>
    </div>
  );
};
