import './Arrow.scss';

import React from 'react';

import { Color } from '../../../models/color';

type Props = {
  color: Color;
};

export const Arrow: React.FC<Props> = ({ color }) => (
  <div className="arrow">
    <img src={`images/arrow-${color}.svg`} alt="A arrow pointing down" />
  </div>
);
