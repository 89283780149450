import React from 'react';

import { ClientContainer } from '../components/ClientContainer/ClientContainer';
import { ContactCardContainer } from '../components/ContactContainer/ContactCardContainer';
import { Header } from '../components/Header/Header';
import HelmetMetadata from '../components/HelmetMetadata/HelmetMetadata';
import { Quote } from '../components/Quote/Quote';
import { SideImageContainer } from '../components/SideImageContainer/SideImageContainer';
import { TextBannerContainer } from '../components/TextBannerContainer/TextBannerContainer';

import { ButtonStyleType, ButtonType, ButtonViewModel } from '../models/button';
import { ClientContainerViewModel } from '../models/client-container';
import { Color } from '../models/color';
import { ContactCardContainerViewModel } from '../models/contact-card-container';
import { CurrentPage } from '../models/current-page';
import { HeaderViewModel } from '../models/header';
import { QuoteBy, QuoteViewModel } from '../models/quote';
import { SideImageContainerViewModel } from '../models/side-image-container';
import { TextOption, TextViewModel } from '../models/text';
import { TextBannerViewModel } from '../models/text-banner';

const getTextBanner = (): TextBannerViewModel => {
  const header = new TextViewModel('The peslo process', Color.Paper, TextOption.HeaderOne, Color.SunsetSand);
  const subHeader = new TextViewModel(
    'Read more about our proven process...',
    Color.SunsetSand,
    TextOption.HeaderThree,
    undefined
  );
  const button = new ButtonViewModel('Process', '/peslo', ButtonStyleType.Secondary, ButtonType.Link);

  return new TextBannerViewModel(Color.Evergreen, header, subHeader, button);
};

const currentPage = CurrentPage.Home;

export const Home = () => (
  <div>
    <HelmetMetadata title="Peslo" />
    <Header model={HeaderViewModel.getHomeHeader(currentPage)} />
    <SideImageContainer model={SideImageContainerViewModel.getHomePageMission()} />
    <TextBannerContainer model={getTextBanner()}>
      <div className="my-4">
        <p className="f-paper text-center">
          Our passion is in building apps that improve our world and deliver positive social change. With years of
          experience in building world-class mobile applications, we empower our clients in their mission to fight the
          climate crisis, help disadvantaged communities, and drive the transition to sustainable technologies.
        </p>
        <p className="f-paper text-center">
          Our focus is on delivering these urgent changes with haste, whilst aligning with the high levels of quality
          users expect, so over time we've refined & streamlined our processes...
        </p>
      </div>
    </TextBannerContainer>

    <ClientContainer model={ClientContainerViewModel.getBasicClientContainerModel(Color.SunsetSand)} />
    <Quote model={QuoteViewModel.getQuoteFor(QuoteBy.Ryan, Color.HoverSage)} />
    <ContactCardContainer model={ContactCardContainerViewModel.getMissionContainer(currentPage)} />
  </div>
);
