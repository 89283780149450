import './Footer.scss';

import React from 'react';

import { LogoType } from '../../models/logo';
import { NavigationItemViewModel } from '../../models/navigation-item';
import { SocialType } from '../../models/social-icon';

import {
  clutchBadge1Location,
  clutchBadge2Location,
  clutchBadge3Location,
  clutchBadgeAlt,
  clutchBadgeExtension,
  clutchBadgeURL
} from '../../constants/clutch-badge';

import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { NavigationItem } from '../Navigation/NavigationItem/NavigationItem';
import { SocialIcon } from '../SocialIcon/SocialIcon';

const renderSocialIcons = () => (
  <div className="row flex-row align-items-center footer-social-container">
    <div className="mt-auto d-flex footer-social-items py-3">
      <SocialIcon type={SocialType.Linkedin} />
      <SocialIcon type={SocialType.Twitter} />
      <SocialIcon type={SocialType.Facebook} />
      <SocialIcon type={SocialType.GitHub} />
    </div>
  </div>
);

const renderFooterImages = () => (
  <div className="row pb-3">
    <div className="footer-image-item col-lg-6 col-12 d-flex justify-content-left align-item-center">
      <div className="d-flex align-items-center">
        <CompanyLogo type={LogoType.FullSand} />
      </div>
    </div>

    <div className="footer-image-item col-lg-6 col-12 d-flex text-center justify-content-end badge-icon align-item-center mt-4 mt-lg-0">
      <a href={clutchBadgeURL}>
        <img src={`${clutchBadge1Location}.${clutchBadgeExtension}`} alt={clutchBadgeAlt} />
        <img src={`${clutchBadge2Location}.${clutchBadgeExtension}`} alt={clutchBadgeAlt} />
        <img src={`${clutchBadge3Location}.${clutchBadgeExtension}`} alt={clutchBadgeAlt} />
      </a>
    </div>
  </div>
);

export const Footer = () => (
  <footer className="bg-evergreen py-2 footer-container container-fluid">
    <div className="container-xxl py-3 px-5">
      {renderFooterImages()}

      <div className="row d-flex flex-row align-items-center justify-content-center py-1">
        <div className="col-12 d-flex flex-row align-items-center footer-nav-items">
          <NavigationItem model={NavigationItemViewModel.getHomeLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getProjectsLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getServicesLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getPesloLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getContactLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getBlogLink(undefined)} />
        </div>
      </div>

      <div className="row d-flex flex-row align-items-center justify-content-center py-1">
        <div className="col-12 d-flex flex-row align-items-center footer-nav-items">
          <NavigationItem model={NavigationItemViewModel.getCookiePolicyLink(undefined)} />
          <NavigationItem model={NavigationItemViewModel.getPrivacyPolicyLink(undefined)} />
        </div>
      </div>

      {renderSocialIcons()}

      <div className="row align-items-center justify-content-left">
        <div className="footer-company-info-container col-12 d-flex flex-row align-items-center  pt-5 pb-4">
          <small className="my-1 mx-3 f-light-sage">{`Copyright 2018-${new Date().getFullYear()} © Peslo Studios Ltd`}</small>
          <small className="mt-1 mx-3 f-light-sage">Company №. 11490065 | VAT №. 304077924</small>
          <small className="mt-1 mx-3 f-light-sage">3 Heron Way, Aldermaston, Reading, England, RG7 4UU</small>
        </div>
      </div>
    </div>
  </footer>
);
