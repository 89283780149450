import './index.scss';

import React from 'react';

import { FieldName, FormFieldType } from '../../../../../models/form-field';

interface Props {
  name: FieldName;
  label: string;
  placeholder: string;
  type: FormFieldType;
  errorMessage: string;
  valid?: boolean;
  touched?: boolean;
  onChanged: (value: string, type: FormFieldType, name: FieldName) => void;
  onTouched: () => void;
}

export const FormField = ({
  errorMessage,
  label,
  name,
  onChanged,
  onTouched,
  placeholder,
  touched,
  type,
  valid
}: Props) => {
  const getInput = () => {
    if (type === FormFieldType.TextArea) {
      return (
        <textarea
          id={`${name}-id`}
          name={name}
          required
          minLength={2}
          data-valid={valid}
          data-touched={touched}
          onBlur={onTouched}
          onFocus={onTouched}
          onChange={event => onChanged(event.target.value, type, name)}
        />
      );
    }

    return (
      <input
        id={`${name}-id`}
        name={name}
        type={type}
        required
        placeholder={placeholder}
        minLength={2}
        data-valid={valid}
        data-touched={touched}
        onChange={event => onChanged(event.target.value, type, name)}
        onBlur={onTouched}
        onFocus={onTouched}
      />
    );
  };

  return (
    <div className="form-field d-flex flex-column my-4">
      <label className="h3 f-evergreen" htmlFor={`${name}-id`}>
        {label}
      </label>
      {getInput()}
      <div className="mt-2 error-message align-items-center">
        <img className="me-2" src="images/icons/warning.svg" alt="Exclamation mark in a red circle" />
        <small>{errorMessage}</small>
      </div>
    </div>
  );
};
