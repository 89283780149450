import React, { useState } from 'react';

import { ContactCalendar } from '../ContactCalendar';
import { ContactForm } from '../ContactForm';
import { ContactPagePicker, ContactPageSegmentIndex } from '../ContactPagePicker';
import { TrustedBySection } from '../TrustedBySection';

export const ContactPageContent = () => {
  const [segment, setSegment] = useState<ContactPageSegmentIndex>(0);

  return (
    <div className="d-flex p-5 flex-column bg-hover-sage">
      <ContactPagePicker segment={segment} onSetSegment={setSegment} />
      <div className="row">
        <div className="col-12 col-xl-8">
          {segment === 0 && <ContactCalendar />}
          {segment === 1 && <ContactForm />}
        </div>
        <div className="col-12 col-xl-4">
          <TrustedBySection />
        </div>
      </div>
    </div>
  );
};
