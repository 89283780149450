import './Button.scss';

import React from 'react';

import { ButtonType, ButtonViewModel } from '../../models/button';

type Props = {
  model: ButtonViewModel;
  disabled?: boolean;
  loading?: boolean;
};

export const Button: React.FC<Props> = ({ disabled = false, loading, model }) => {
  if (model.type === ButtonType.Submit) {
    return (
      <button type="submit" className={`btn mx-1 px-5 py-2 btn-${model.styleType}`} disabled={disabled || loading}>
        {!loading && model.text}
        {loading && <div className="loader" />}
      </button>
    );
  }

  return (
    <a className={`mx-auto btn mx-1 px-5 py-2 btn-${model.styleType}`} href={model.link}>
      {model.text}
    </a>
  );
};
