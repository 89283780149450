import './ImageContainer.scss';

import React from 'react';

import { CornerViewModel } from '../../models/corner';
import { ImageBadgeViewModel } from '../../models/image-badge';
import { ImageContainerViewModel } from '../../models/image-container';
import { Position } from '../../models/position';

import { Corner } from '../Symbol/Corner/Corner';

import { ImageBadges } from './ImageBadges';

type Props = {
  model: ImageContainerViewModel;
  subImages?: ImageBadgeViewModel[];
  imageClassName?: string;
};

export const ImageContainer: React.FC<Props> = ({ imageClassName, model, subImages }) => {
  const { alt, bottomCornerPosition, color, extension, topCornerPosition, url } = model;
  let srcset = `${url}.${extension}`;
  let src = `${url}.${extension}`;

  if (model.retina) {
    srcset = `${url}-1x.${extension} 1x, ${url}-2x.${extension} 2x, ${url}-3x.${extension} 3x`;
    src = `${url}-3x.${extension}`;
  }

  return (
    <div className="position-relative image-container h-100">
      <Corner model={CornerViewModel.getForPositions(Position.Start, topCornerPosition, color)} />
      <div className="position-relative w-100 d-flex p-3 justify-content-center h-100">
        <img className={imageClassName} srcSet={srcset} src={src} alt={alt} />
        <ImageBadges images={subImages} />
      </div>
      <Corner model={CornerViewModel.getForPositions(Position.End, bottomCornerPosition, color)} />
    </div>
  );
};

export default ImageContainer;
