import './index.scss';

import React from 'react';

import { ClientLogo } from '../../../../components/ClientLogo/ClientLogo';

import { ClientOption } from '../../../../models/client';

import { ContactHeader } from '../ContactHeader';

const renderClientLogo = (client: ClientOption) => (
  <div className="col-6 col-xl-12 d-flex justify-content-center justify-content-xl-start my-4">
    <ClientLogo clientOption={client} />
  </div>
);

export const TrustedBySection = () => (
  <div className="trusted-by-container d-flex flex-column mt-lg-5 mt-xl-0 px-lg-0 px-xl-5">
    <ContactHeader
      title="trusted by"
      subtitle="We've worked with these change-makers to improve our world. Join them here."
    />
    <div className="mt-3 row">
      {renderClientLogo(ClientOption.EVEnergy)}
      {renderClientLogo(ClientOption.Zilch)}
      {renderClientLogo(ClientOption.AndDigital)}
      {renderClientLogo(ClientOption.PerchPeek)}
      {renderClientLogo(ClientOption.Cherryz)}
      {renderClientLogo(ClientOption.ThreeSidedCube)}
    </div>
  </div>
);
