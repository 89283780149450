import { Color } from './color';
import { StepViewModel } from './StepViewModel';

export class StepContainerViewModel {
  public backgroundColor: Color;

  public titleColor: Color;

  public secondaryColor: Color;

  public title: string;

  public steps: StepViewModel[];

  constructor(backgroundColor: Color, titleColor: Color, secondaryColor: Color, title: string, steps: StepViewModel[]) {
    this.backgroundColor = backgroundColor;
    this.titleColor = titleColor;
    this.secondaryColor = secondaryColor;
    this.title = title;
    this.steps = steps;
  }

  static pesloProcessContainer() {
    const secondaryColor: Color = Color.SunsetSand;

    return new StepContainerViewModel(
      Color.Evergreen,
      Color.Paper,
      secondaryColor,
      'the peslo process',
      StepViewModel.pesloProcessSteps(Color.LightEvergreen, secondaryColor, Color.Paper)
    );
  }
}
