import { Color } from './color';
import { ImageExtension } from './image';
import { ImageContainerViewModel } from './image-container';
import { Position } from './position';
import { TextOption, TextViewModel } from './text';

export class ServiceCardViewModel {
  public image: ImageContainerViewModel;

  public header: TextViewModel;

  public text: TextViewModel;

  constructor(image: ImageContainerViewModel, header: TextViewModel, text: TextViewModel) {
    this.image = image;
    this.header = header;
    this.text = text;
  }

  static getHybridServiceCard() {
    return new ServiceCardViewModel(
      new ImageContainerViewModel(
        Position.Start,
        Position.End,
        '/images/service/hybrid',
        ImageExtension.SVG,
        false,
        'Apple and Google logo underneath React Native logo',
        Color.SunsetSand
      ),
      new TextViewModel('Hybrid (React Native)', Color.Evergreen, TextOption.HeaderThree, undefined),
      new TextViewModel(
        "There's nothing in React Native we don't know. We use React Native to deliver complex mobile apps for iOS and Android, and specialise in utilising platform-specific native code when required. We put special focus on ensuring your app feels perfect on both platforms, whilst matching your brand guidelines",
        Color.Evergreen,
        TextOption.Body,
        undefined
      )
    );
  }

  static getNativeServiceCard() {
    return new ServiceCardViewModel(
      new ImageContainerViewModel(
        Position.Start,
        Position.End,
        '/images/service/native',
        ImageExtension.SVG,
        false,
        'Apple logo',
        Color.SunsetSand
      ),
      new TextViewModel('iOS Native (Swift & SwiftUI)', Color.Evergreen, TextOption.HeaderThree, undefined),
      new TextViewModel(
        "We use Apple's latest technology to deliver stunning mobile experiences across iOS, iPadOS, and watchOS. We're also comfortable with Objective-C for maintaining legacy projects, and aren't challenged by some of the niche requirements of developing for Apple platforms",
        Color.Evergreen,
        TextOption.Body,
        undefined
      )
    );
  }
}
