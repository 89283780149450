import React from 'react';

import { NavigationItemViewModel } from '../../../models/navigation-item';

type Props = {
  model: NavigationItemViewModel;
};

export const NavigationItem: React.FC<Props> = ({ model }) => {
  const isSelected = model.currentPage === model.page;
  const colorSuffix = isSelected ? 'paper' : model.color;

  return (
    <a
      className={`nav-link d-flex flex-column align-items-center f-${colorSuffix} ${isSelected ? 'nav-selected' : ''}`}
      href={model.link}>
      {model.title}
      <div className={`bt-${colorSuffix} nav-link-line`} />
    </a>
  );
};
