import React from 'react';

import { Color } from '../../../../models/color';

import { getUnderscoreClasses } from '../../../../constants/underscore';

export const ContactHeader = ({ subtitle, title }: { title: string; subtitle: string }) => (
  <>
    <h2 className={`h1 text-lowercase f-evergreen mb-3 ${getUnderscoreClasses(Color.Sage)}`}>{title}</h2>
    <p className="f-evergreen mb-3">{subtitle}</p>
  </>
);
