import './ImageBadge.scss';

import React from 'react';

import { ImageBadgeViewModel } from '../../../models/image-badge';

interface Props {
  images?: ImageBadgeViewModel[];
}

const renderImage = (image: ImageBadgeViewModel) => {
  let srcset = `${image?.url}.${image?.extension}`;
  let src = `${image?.url}.${image?.extension}`;

  if (image?.retina) {
    srcset = `${image?.url}-1x.${image?.extension} 1x, ${image?.url}-2x.${image?.extension} 2x, ${image?.url}-3x.${image?.extension} 3x`;
    src = `${image?.url}-3x.${image?.extension}`;
  }

  const alt = image?.alt;

  return (
    <a href={image?.href}>
      <img className={`image-badge ${image?.customClass}`} src={src} srcSet={srcset} alt={alt} />
    </a>
  );
};

export const ImageBadges: React.FC<Props> = ({ images }) => {
  if (!images) {
    return null;
  }

  return (
    <div className="image-badge-container w-100 d-flex justify-content-center align-items-center">
      <div className="p-3 d-flex justify-content-center align-items-center">
        {images.map(image => renderImage(image))}
      </div>
    </div>
  );
};
