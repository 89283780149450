import { Color } from './color';

export enum TextOption {
  Body = 0,
  HeaderOne = 1,
  HeaderTwo = 2,
  HeaderThree = 3
}

export class TextViewModel {
  public text: string;

  public color: Color;

  public textOption: TextOption;

  public underscore: Color | undefined;

  constructor(text: string, color: Color, textOption: TextOption, underscore: Color | undefined) {
    this.text = text;
    this.color = color;
    this.textOption = textOption;
    this.underscore = underscore;
  }
}
