import { Color } from './color';
import { ServiceCardViewModel } from './ServiceCardViewModel';
import { TextOption, TextViewModel } from './text';

export class ServiceCardContainerViewModel {
  public header: TextViewModel;

  public cards: ServiceCardViewModel[];

  constructor(header: TextViewModel, cards: ServiceCardViewModel[]) {
    this.header = header;
    this.cards = cards;
  }

  static getServiceCardContainer() {
    return new ServiceCardContainerViewModel(
      new TextViewModel('Services', Color.Evergreen, TextOption.HeaderOne, Color.SunsetSand),
      this.getServiceCardList()
    );
  }

  private static getServiceCardList(): ServiceCardViewModel[] {
    const cards = [];
    cards.push(ServiceCardViewModel.getHybridServiceCard());
    cards.push(ServiceCardViewModel.getNativeServiceCard());

    return cards;
  }
}
