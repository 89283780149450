import './ServiceCard.scss';

import React from 'react';

import { ServiceCardViewModel } from '../../../../models/ServiceCardViewModel';

import { ImageContainer } from '../../../ImageContainer/ImageContainer';

type Props = {
  model: ServiceCardViewModel;
};

export const ServiceCard: React.FC<Props> = ({ model }) => (
  <div className="col-12 col-lg-6 my-2 py-3 px-0 px-lg-5">
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="service-card-image-container">
        <ImageContainer imageClassName="card-shadow" model={model.image} />
      </div>

      <h3 className={`pt-5 pb-3 text-center ${model.header.textOption} f-${model.header.color}`}>
        {model.header.text}
      </h3>
      <p className={`text-center f-${model.text.color}`}>{model.text.text}</p>
    </div>
  </div>
);
