import './ProjectCard.scss';

import React from 'react';

import { ImageContainerViewModel } from '../../../models/image-container';
import { Position } from '../../../models/position';
import { ProjectCardViewModel } from '../../../models/project-card';

import { ImageContainer } from '../../ImageContainer/ImageContainer';

import { ProjectIcon } from './ProjectIcon/ProjectIcon';
import { ProjectRating } from './ProjectRating/ProjectRating';

type Props = {
  model: ProjectCardViewModel;
};

export const ProjectCard: React.FC<Props> = ({ model }) => {
  function getCardDetails() {
    return !model.navCard ? (
      <div>
        <h3 className={`h3 f-${model.title.color} text-center pt-4`}>{model.title.text}</h3>
        <ProjectRating rating={model.rating} number={model.index} />
        <p className={`text-center f-${model.subText.color}`}>{model.subText.text}</p>
      </div>
    ) : null;
  }

  return (
    <div className="col-12 col-xl-4 my-2 py-3 px-5 project-card-main-container">
      <a href={model.link} className="project-card-container">
        <div className={`p-3 project-card ${!model.navCard ? 'bg-paper' : ''}`}>
          <div className="position-relative">
            <ImageContainer
              model={ImageContainerViewModel.getImageContainer(
                `images/projects/${model.image}`,
                model.extension,
                model.retina,
                model.imageAlt,
                Position.Start,
                Position.End,
                model.borderColor
              )}
            />
            <ProjectIcon color={model.iconColor} image={model.iconImage} />
          </div>
          {getCardDetails()}
        </div>
      </a>
    </div>
  );
};
