import './index.scss';

import React, { useEffect } from 'react';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Footer } from './components/Footer/Footer';

import { Contact } from './pages/contact';
import { Home } from './pages/Home';
import { Legal } from './pages/Legal';
import { Peslo } from './pages/Peslo';
import { Projects } from './pages/Projects';
import { Services } from './pages/Services';

import { CurrentPage } from './models/current-page';

import { analytics } from './utils/analytics';

import CookiePolicy from './constants/cookies';
import PerceptionPrivacy from './constants/perception-privacy';
import PerceptionTerms from './constants/perception-terms';
import PrivacyPolicy from './constants/privacy';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App = () => {
  useEffect(() => {
    analytics.initialize();
  }, []);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/peslo" element={<Peslo />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/privacy"
          element={<Legal content={PrivacyPolicy} page={CurrentPage.PrivacyPolicy} title="Privacy Policy" />}
        />
        <Route
          path="/cookies"
          element={<Legal content={CookiePolicy} page={CurrentPage.CookiePolicy} title="Cookie Policy" />}
        />
        <Route
          path="/perception-terms"
          element={
            <Legal content={PerceptionTerms} page={CurrentPage.PerceptionTerms} title="Perception Terms of Use" />
          }
        />
        <Route
          path="/perception-privacy"
          element={
            <Legal content={PerceptionPrivacy} page={CurrentPage.PerceptionPrivacy} title="Perception Privacy Policy" />
          }
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </HelmetProvider>
  );
};

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
    <Footer />
  </React.StrictMode>
);
