export enum CurrentPage {
  Home,
  Projects,
  Services,
  Peslo,
  Contact,
  Blog,
  CookiePolicy,
  PerceptionTerms,
  PerceptionPrivacy,
  PrivacyPolicy,
  TermsOfService,
  SiteMap
}
