import { Color } from './color';
import { CurrentPage } from './current-page';

export class NavigationItemViewModel {
  link: string;

  title: string;

  currentPage: CurrentPage | undefined;

  page: CurrentPage;

  color: Color;

  constructor(link: string, title: string, currentPage: CurrentPage | undefined, page: CurrentPage, color: Color) {
    this.link = link;
    this.title = title;
    this.currentPage = currentPage;
    this.page = page;
    this.color = color;
  }

  static setLinkColorOption(isHeader: boolean) {
    return isHeader ? Color.Sage : Color.Paper;
  }

  static getHomeLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel('/', 'Home', currentPage, CurrentPage.Home, this.setLinkColorOption(isHeader));
  }

  static getProjectsLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/projects',
      'Projects',
      currentPage,
      CurrentPage.Projects,
      this.setLinkColorOption(isHeader)
    );
  }

  static getServicesLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/services',
      'Services',
      currentPage,
      CurrentPage.Services,
      this.setLinkColorOption(isHeader)
    );
  }

  static getPesloLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/peslo',
      'Peslo?',
      currentPage,
      CurrentPage.Peslo,
      this.setLinkColorOption(isHeader)
    );
  }

  static getContactLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/contact',
      'Contact',
      currentPage,
      CurrentPage.Contact,
      this.setLinkColorOption(isHeader)
    );
  }

  static getBlogLink(currentPage: CurrentPage | undefined, isHeader = false): NavigationItemViewModel {
    return new NavigationItemViewModel(
      'https://blog.peslostudios.com',
      'Blog',
      currentPage,
      CurrentPage.Blog,
      this.setLinkColorOption(isHeader)
    );
  }

  static getCookiePolicyLink(currentPage: CurrentPage | undefined): NavigationItemViewModel {
    return new NavigationItemViewModel('/cookies', 'Cookie Policy', currentPage, CurrentPage.CookiePolicy, Color.Sage);
  }

  static getPrivacyPolicyLink(currentPage: CurrentPage | undefined): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/privacy',
      'Privacy Policy',
      currentPage,
      CurrentPage.PrivacyPolicy,
      Color.Sage
    );
  }

  static getTermsOfServiceLink(currentPage: CurrentPage | undefined): NavigationItemViewModel {
    return new NavigationItemViewModel(
      '/terms-of-service',
      'Terms of Service',
      currentPage,
      CurrentPage.TermsOfService,
      Color.Sage
    );
  }

  static getSiteMapLink(currentPage: CurrentPage | undefined): NavigationItemViewModel {
    return new NavigationItemViewModel('/site-map', 'Site Map', currentPage, CurrentPage.SiteMap, Color.Sage);
  }
}
