import { Color } from './color';
import { Position } from './position';

export class CornerViewModel {
  color: Color;

  yPosition: Position;

  xPosition: Position;

  constructor(color: Color, xPosition: Position, yPosition: Position) {
    this.color = color;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
  }

  static getForPositions(xPosition: Position, yPosition: Position, color: Color = Color.Evergreen) {
    return new CornerViewModel(color, xPosition, yPosition);
  }

  static getTopLeftCorner(color: Color = Color.Evergreen) {
    return new CornerViewModel(color, Position.Start, Position.Start);
  }

  static getTopRightCorner(color: Color = Color.Evergreen) {
    return new CornerViewModel(color, Position.Start, Position.End);
  }

  static getBottomLeftCorner(color: Color = Color.Evergreen) {
    return new CornerViewModel(color, Position.End, Position.Start);
  }

  static getBottomRightCorner(color: Color = Color.Evergreen) {
    return new CornerViewModel(color, Position.End, Position.End);
  }
}
